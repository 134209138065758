// path: src/features/Auth/RegisterPage.js
import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../../actions/userActions"; // Pastikan untuk membuat aksi ini di Redux

const RegisterPage = () => {
  const [namaLengkap, setNamaLengkap] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [nomorTelepon, setNomorTelepon] = useState("");
  const [alamat, setAlamat] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleRegister = () => {
    const userData = {
      namaLengkap,
      username,
      email,
      password,
      pin,
      nomorTelepon,
      alamat,
    };
    dispatch(registerUser(userData)).then(() => {
      navigate("/login"); // Atur rute navigasi sesuai kebutuhan
    });
  };

  return (
    <Box
      container
      sx={{
        display: "flex",
        height: "100vh",
        backgroundImage: "url(/assets/images/background-images.webp)", // Gunakan URL relatif
        backgroundColor: "black",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        margin: 0,
        overflow: "auto",
      }}
    >
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={4}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "16px",
            px: isMobile ? "20px" : "90px",
            py: isMobile ? "20px" : "60px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Registrasi Akun
          </Typography>
          <TextField
            label="Nama Lengkap"
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
            value={namaLengkap}
            onChange={(e) => setNamaLengkap(e.target.value)}
            helperText="Masukkan nama lengkap Anda"
          />
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            helperText="Harap selalu ingat username Anda"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            helperText="Masukkan alamat email kerja Anda"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            size="small"
            margin="normal"
            value={password}
            helperText="Pastikan password mudah di ingat. Admin tidak tahu password anda"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="PIN"
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            helperText="PIN kombinasi 4 digit angka"
            inputProps={{ maxLength: 4 }}
          />
          <TextField
            label="Nomor Telepon"
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
            value={nomorTelepon}
            onChange={(e) => setNomorTelepon(e.target.value)}
            helperText="Masukkan nomor telepon kerja Anda"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Alamat"
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
            value={alamat}
            helperText="Masukkan alamat / kecamatan domisili sekarang"
            onChange={(e) => setAlamat(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={handleRegister}
            sx={{
              backgroundColor: "#006D5A", // Ganti dengan warna latar belakang yang diinginkan
              color: "#FFFFFF", // Ganti dengan warna teks yang kontras dengan latar belakang
              mt: 2,
              ":hover": {
                backgroundColor: "#005a4f", // Ganti dengan warna latar belakang yang diinginkan saat tombol dihover
              },
            }}
          >
            Register
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      ></Grid>
    </Box>
  );
};

export default RegisterPage;
