// path: src/features/products/components/KinamiForm.js

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchKinamiData } from "../productSlice";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";

const KinamiForm = ({ product, setProduct }) => {
  const dispatch = useDispatch();
  const { kain, style, kaki, dudukan } = useSelector(
    (state) => state.products.metadata
  );

  useEffect(() => {
    dispatch(fetchKinamiData());
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      kinamiSpecs: {
        ...(prevProduct.kinamiSpecs || {}),
        [name]: value,
      },
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      kinamiSpecs: {
        ...(prevProduct.kinamiSpecs || {}),
        [name]: checked,
      },
    }));
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Style</InputLabel>
            <Select
              name="id_style"
              value={product.kinamiSpecs?.id_style || ""}
              onChange={handleChange}
              label="Style"
            >
              {style.map((item) => (
                <MenuItem key={item.id_style} value={item.id_style}>
                  {item.style}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Jenis Kain</InputLabel>
            <Select
              name="id_kain"
              value={product.kinamiSpecs?.id_kain || ""}
              onChange={handleChange}
              label="Jenis Kain"
            >
              {kain.map((item) => (
                <MenuItem key={item.id_kain} value={item.id_kain}>
                  {item.kain}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Jenis Dudukan</InputLabel>
            <Select
              name="id_dudukan"
              value={product.kinamiSpecs?.id_dudukan || ""}
              onChange={handleChange}
              label="Jenis Dudukan"
            >
              {dudukan.map((item) => (
                <MenuItem key={item.id_dudukan} value={item.id_dudukan}>
                  {item.dudukan}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Jenis Kaki</InputLabel>
            <Select
              name="id_kaki"
              value={product.kinamiSpecs?.id_kaki || ""}
              onChange={handleChange}
              label="Jenis Kaki"
            >
              {kaki.map((item) => (
                <MenuItem key={item.id_kaki} value={item.id_kaki}>
                  {item.jenis_kaki}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Jumlah Bantal Peluk"
            name="bantal_peluk"
            type="number"
            value={product.kinamiSpecs?.bantal_peluk || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Jumlah Bantal Sandaran"
            name="bantal_sandaran"
            type="number"
            value={product.kinamiSpecs?.bantal_sandaran || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Jumlah Kantong Remot"
            name="kantong_remot"
            type="number"
            value={product.kinamiSpecs?.kantong_remot || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={product.kinamiSpecs?.puff || false}
                onChange={handleCheckboxChange}
                name="puff"
              />
            }
            label="Apakah termasuk Puff?"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default KinamiForm;
