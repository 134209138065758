// path: src/features/sales/OrderModal/KayuForm.js
import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const KayuForm = ({
  product,
  onAdditionalChange,
  onDataChange,
  validationErrors = {},
}) => {
  const [finishingOptions, setFinishingOptions] = useState([]);
  const [selectedFinishing, setSelectedFinishing] = useState("");
  const [selectedFinishingId, setSelectedFinishingId] = useState("");
  const [additionalOptions, setAdditionalOptions] = useState([
    { jenis: "", nilai: "", harga: "" },
  ]);
  const totalAdditional = additionalOptions.reduce((acc, option) => {
    const harga = parseFloat(option.harga) || 0;
    return acc + harga;
  }, 0);

  useEffect(() => {
    const fetchFinishingOptions = async () => {
      try {
        const response = await fetch(
          "https://kasir.ajegjogja.com/api/options/finishing"
        );
        if (!response.ok) {
          throw new Error("Error fetching finishing options");
        }
        const data = await response.json();
        setFinishingOptions(data);
      } catch (error) {
        console.error("Fetching finishing options failed:", error.message);
      }
    };

    fetchFinishingOptions();
  }, []);

  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const handleAdditionalChange = (index, field, value) => {
    const updatedOptions = [...additionalOptions];
    updatedOptions[index][field] = value;
    setAdditionalOptions(updatedOptions);
    updateTotalAdditional(updatedOptions);
    onDataChange({
      additionalOptions: updatedOptions,
      finishing: selectedFinishing,
      id_finishing: selectedFinishingId,
    });
  };

  const updateTotalAdditional = (options) => {
    const totalAdditional = options.reduce((acc, option) => {
      const harga = parseFloat(option.harga) || 0;
      return acc + harga;
    }, 0);
    console.log("KayuForm mengirim total additional:", totalAdditional);
    onAdditionalChange(totalAdditional);
  };

  const handleHargaChange = (index, value) => {
    const formattedValue = value.replace(/[^\d]/g, "");
    const numericValue = formattedValue ? parseFloat(formattedValue) : 0;
    handleAdditionalChange(index, "harga", numericValue);
  };

  const removeAdditionalOption = (index) => {
    const updatedOptions = additionalOptions.filter((_, i) => i !== index);
    setAdditionalOptions(updatedOptions);
    updateTotalAdditional(updatedOptions);
  };

  const handleFinishingChange = (value, id) => {
    setSelectedFinishing(value);
    setSelectedFinishingId(id);
    onDataChange({
      additionalOptions,
      finishing: value,
      id_finishing: id,
    });
  };

  return (
    <Box sx={{ maxHeight: 350, overflowY: "scroll" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body2">Dimensi</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            : {product.panjang} x {product.lebar} x {product.tinggi} cm
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} mt={1}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
            Additional Charges / Custom:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
            {formatRupiah(totalAdditional)}
          </Typography>
        </Grid>
      </Grid>

      <Box
        component="ul"
        p={1}
        m={0}
        sx={{ border: "1px solid #ccc", borderRadius: 2, mt: 1 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
              Jenis
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
              Nilai
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
              Harga
            </Typography>
          </Grid>

          {additionalOptions.map((option, index) => (
            <React.Fragment key={index}>
              <Grid item xs={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id={`jenis-label-${index}`}>
                    Pilih Jenis
                  </InputLabel>
                  <Select
                    labelId={`jenis-label-${index}`}
                    value={option.jenis}
                    label="Pilih Jenis"
                    onChange={(event) =>
                      handleAdditionalChange(index, "jenis", event.target.value)
                    }
                  >
                    <MenuItem value="Dimensi">Dimensi</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="small"
                  value={option.nilai}
                  onChange={(event) =>
                    handleAdditionalChange(index, "nilai", event.target.value)
                  }
                  placeholder="Masukkan nilai"
                  error={
                    !!validationErrors[`additionalOptions[${index}].nilai`]
                  }
                  helperText={
                    validationErrors[`additionalOptions[${index}].nilai`] || ""
                  }
                />
              </Grid>
              <Grid item xs={4} display="flex">
                <TextField
                  fullWidth
                  size="small"
                  value={option.harga}
                  onChange={(event) =>
                    handleHargaChange(index, event.target.value)
                  }
                  placeholder="Masukkan harga"
                  error={
                    !!validationErrors[`additionalOptions[${index}].harga`]
                  }
                  helperText={
                    validationErrors[`additionalOptions[${index}].harga`] || ""
                  }
                />
                <IconButton
                  onClick={() => removeAdditionalOption(index)}
                  color="secondary"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              size="small"
              error={!!validationErrors.finishing}
            >
              <InputLabel id="pilihan-finishing">Pilih Finishing</InputLabel>
              <Select
                labelId="pilihan-finishing"
                value={selectedFinishing}
                label="Finishing"
                onChange={(e) => {
                  const selectedOption = finishingOptions.find(
                    (option) => option.finishing === e.target.value
                  );
                  handleFinishingChange(
                    e.target.value,
                    selectedOption.id_finishing
                  );
                }}
              >
                {finishingOptions.map((finishing) => (
                  <MenuItem
                    key={finishing.id_finishing}
                    value={finishing.finishing}
                  >
                    {finishing.finishing}
                  </MenuItem>
                ))}
              </Select>

              {validationErrors.finishing && (
                <Typography color="error" variant="body2">
                  {validationErrors.finishing}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default KayuForm;
