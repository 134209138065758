// path: src/features/orders/orderslice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";

const BASE_URL = process.env.REACT_APP_BASE_URL; // Ambil BASE_URL dari environment variable

const token = localStorage.getItem("token"); // Ambil token dari local storage

// Thunk untuk mengambil data orders
export const fetchOrders = createAsyncThunk("orders/fetchOrders", async () => {
  try {
    const response = await axiosInstance.get("/api/orders");
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Thunk untuk mengambil detail pesanan berdasarkan transaction_id
export const fetchOrderDetails = createAsyncThunk(
  "orders/fetchOrderDetails",
  async (transactionId) => {
    try {
      const response = await axiosInstance.get(`/api/orders/${transactionId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Perbaiki updateOrderStatus thunk
export const updateOrderStatus = createAsyncThunk(
  "orders/updateOrderStatus",
  async ({ transactionId, detailId, status }) => {
    try {
      const response = await axiosInstance.put(
        `/api/orders/${transactionId}/details/${detailId}`,
        { status }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Thunk untuk melakukan pelunasan
export const settlePayment = createAsyncThunk(
  "orders/settlePayment",
  async ({ transactionId, jumlahPembayaran, metodePembayaran }) => {
    console.log("Payload received in thunk:", {
      transactionId,
      jumlahPembayaran,
      metodePembayaran,
    });

    try {
      const response = await axiosInstance.post(`/api/orders/settlement`, {
        transactionId,
        jumlahPembayaran,
        metodePembayaran,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    orders: [],
    orderDetails: null, // Tambahkan state untuk menyimpan detail pesanan
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchOrderDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orderDetails = action.payload;
      })
      .addCase(fetchOrderDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        const { detailId, status } = action.payload;
        const detail = state.orderDetails.details.find(
          (d) => d.id === detailId
        );
        if (detail) {
          detail.detail_status = status;
        }
      })
      .addCase(settlePayment.fulfilled, (state, action) => {
        const { jumlah_pembayaran } = action.payload;
        // Update state jika diperlukan setelah pelunasan berhasil
      });
  },
});

export default ordersSlice.reducer;
