// path: src/features/orders/components/Sidebar.js

import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import theme from "../../../theme";
import CustomDateRangePicker from "../../dashboard/components/CustomDateRangePicker";
import { startOfToday, endOfToday } from "date-fns";

const Sidebar = ({ onFilter, paymentStatuses, itemStatuses }) => {
  const [query, setQuery] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(2024, 6, 1), // 1 Juli 2024
      endDate: new Date(2099, 11, 31), // 31 Desember 2099
      key: "selection",
    },
  ]);
  const [selectedPaymentStatuses, setSelectedPaymentStatuses] = useState([]);
  const [selectedItemStatuses, setSelectedItemStatuses] = useState([]);

  useEffect(() => {
    onFilter({
      query,
      dateRange: [dateRange[0].startDate, dateRange[0].endDate],
      selectedPaymentStatuses,
      selectedItemStatuses,
    });
  }, [query, dateRange, selectedPaymentStatuses, selectedItemStatuses]);

  useEffect(() => {
    // Jalankan filter pertama kali dengan nilai default dateRange
    onFilter({
      query,
      dateRange: [dateRange[0].startDate, dateRange[0].endDate],
      selectedPaymentStatuses,
      selectedItemStatuses,
    });
  }, []);

  const handleCheckboxChange = (
    status,
    setSelectedStatuses,
    selectedStatuses
  ) => {
    const currentIndex = selectedStatuses.indexOf(status);
    const newChecked = [...selectedStatuses];

    if (currentIndex === -1) {
      newChecked.push(status);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedStatuses(newChecked);
  };

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: 300 },
        padding: 2,
        height: { xs: "auto", sm: "100vh" },
        overflowY: { xs: "visible", sm: "auto" },
        backgroundColor: "white",
        zIndex: 1000,
        pt: 10,
      }}
    >
      <Typography variant="h6">Filter</Typography>
      <CustomDateRangePicker
        selectedDateRange={dateRange}
        setSelectedDateRange={setDateRange}
        fullWidth
      />
      <TextField
        label="Nama Pelanggan"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
        margin="normal"
        size="small"
      />

      <Box>
        <Box margin="normal" component="fieldset" sx={{ borderRadius: 2 }}>
          <legend>Status Pembayaran</legend>
          {paymentStatuses.map((status) => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  checked={selectedPaymentStatuses.indexOf(status) !== -1}
                  onChange={() =>
                    handleCheckboxChange(
                      status,
                      setSelectedPaymentStatuses,
                      selectedPaymentStatuses
                    )
                  }
                />
              }
              label={status}
            />
          ))}
        </Box>
      </Box>
      <Box>
        <Box margin="normal" component="fieldset" sx={{ borderRadius: 2 }}>
          <legend>Status Item</legend>
          {itemStatuses.map((status) => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  checked={selectedItemStatuses.indexOf(status) !== -1}
                  onChange={() =>
                    handleCheckboxChange(
                      status,
                      setSelectedItemStatuses,
                      selectedItemStatuses
                    )
                  }
                />
              }
              label={status}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
