// path: src/features/Sales/PrintReceipt.js
import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import OrderItem from "./OrderItem";
import { parseISO, compareDesc, isWithinInterval } from "date-fns";
import Sidebar from "./Sidebar";
import StatusSummary from "./StatusSummary";
import theme from "../../../theme";
import { startOfDay, endOfDay } from "date-fns";

const OrderList = ({ orders }) => {
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    setFilteredOrders(orders);
  }, [orders]);

  const sortedOrders = useMemo(() => {
    return [...orders].sort((a, b) =>
      compareDesc(parseISO(a.tanggal_transaksi), parseISO(b.tanggal_transaksi))
    );
  }, [orders]);

  useEffect(() => {
    setFilteredOrders(sortedOrders);
  }, [sortedOrders]);

  const handleFilter = ({
    query,
    dateRange,
    selectedPaymentStatuses,
    selectedItemStatuses,
  }) => {
    let filtered = [...orders];

    if (query) {
      filtered = filtered.filter((order) =>
        order.nama_pelanggan.toLowerCase().includes(query.toLowerCase())
      );
    }

    if (dateRange[0] && dateRange[1]) {
      filtered = filtered.filter((order) =>
        isWithinInterval(parseISO(order.tanggal_transaksi), {
          start: startOfDay(dateRange[0]),
          end: endOfDay(dateRange[1]),
        })
      );
    }

    if (selectedPaymentStatuses.length > 0) {
      filtered = filtered.filter((order) =>
        selectedPaymentStatuses.includes(order.status_pembayaran)
      );
    }

    if (selectedItemStatuses.length > 0) {
      filtered = filtered.filter((order) =>
        order.details.some((detail) =>
          selectedItemStatuses.includes(detail.status)
        )
      );
    }

    setFilteredOrders(filtered);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedOrders = filteredOrders.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Extract unique statuses for filters
  const paymentStatuses = [
    ...new Set(orders.map((order) => order.status_pembayaran)),
  ];
  const itemStatuses = [
    ...new Set(
      orders.flatMap((order) => order.details.map((detail) => detail.status))
    ),
  ];

  // Hitung jumlah item untuk setiap status berdasarkan pesanan yang difilter
  const itemStatusCounts = itemStatuses.reduce((acc, status) => {
    acc[status] = filteredOrders.flatMap((order) =>
      (order.details || []).filter((detail) => detail.status === status)
    ).length;
    return acc;
  }, {});

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="nav" sx={{ width: { sm: 300 }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { width: 300 },
            boxShadow: theme.boxShadow.primary,
            elevation: 0,
          }}
        >
          <Sidebar
            onFilter={handleFilter}
            paymentStatuses={paymentStatuses}
            itemStatuses={itemStatuses}
          />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              width: 300,
              elevation: 0,
            },
            boxShadow: theme.boxShadow.primary,
            elevation: 0,
          }}
          open
        >
          <Sidebar
            onFilter={handleFilter}
            paymentStatuses={paymentStatuses}
            itemStatuses={itemStatuses}
            orders={orders}
          />
        </Drawer>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - 300px)` },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <StatusSummary itemStatusCounts={itemStatusCounts} />

        <TableContainer
          component={Paper}
          sx={{
            boxShadow: theme.boxShadow.primary,
            width: "100%",
            overflow: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Tanggal Transaksi</TableCell>
                <TableCell>Sales Name</TableCell>
                <TableCell>Nama Pelanggan</TableCell>
                <TableCell>Tanggal Pengiriman</TableCell>
                <TableCell>Status Pembayaran</TableCell>
                <TableCell>Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedOrders.map((order, index) => (
                <OrderItem
                  key={order.order_number}
                  order={order}
                  index={page * rowsPerPage + index + 1}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={filteredOrders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default OrderList;
