// path: src/features/Sales/OrderConfirmation/CustomerData.js
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setCustomerData } from "../slices/customerSlice"; // Tambahkan ini

const CustomerData = () => {
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState({
    title: "",
    nama: "",
    alamat: "",
    nomorHp: "",
    request_pengiriman: "",
    catatan: "",
    provinsi: { id: "34", name: "DI YOGYAKARTA" },
    kota: "",
    kecamatan: "",
    kelurahan: "",
  });
  const [provinsiOptions, setProvinsiOptions] = useState([]);
  const [kotaOptions, setKotaOptions] = useState([]);
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [kelurahanOptions, setKelurahanOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [existingCustomers, setExistingCustomers] = useState([]);

  useEffect(() => {
    const fetchProvinsi = async () => {
      try {
        const response = await axios.get(
          "https://kasir.ajegjogja.com/api/alamat/provinsi"
        );
        setProvinsiOptions(response.data);
        fetchKota("34"); // Load default kota for provinsi id 34
      } catch (error) {
        console.error("Error fetching provinsi:", error);
      }
    };
    fetchProvinsi();
  }, []);

  const fetchKota = async (provinsiId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://kasir.ajegjogja.com/api/alamat/kota",
        {
          params: { provinsi_id: provinsiId },
        }
      );
      setKotaOptions(response.data);
    } catch (error) {
      console.error(
        "Error fetching kota:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 1) {
      fetchExistingCustomers();
    }
  };

  const fetchExistingCustomers = async () => {
    try {
      const token = localStorage.getItem("token"); // Ambil token dari localStorage atau tempat penyimpanan yang sesuai
      const response = await axios.get(
        "https://kasir.ajegjogja.com/api/customers",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExistingCustomers(response.data);
    } catch (error) {
      console.error("Error fetching existing customers:", error);
    }
  };

  const handleExistingCustomerSelect = (event, value) => {
    if (value) {
      const selectedCustomer = {
        id: value.id,
        title: value.tittle,
        nama: value.nama_pelanggan,
        alamat: value.alamat_pelanggan,
        nomorHp: value.nomor_hp,
        provinsi: { id: value.provinsi, name: value.provinsi },
        kota: value.kabupaten,
        kecamatan: value.kecamatan,
        kelurahan: value.kelurahan,
      };
      setCustomer(selectedCustomer);
      dispatch(setCustomerData(selectedCustomer));
    }
  };

  const handleProvinsiSelect = async (event, value) => {
    if (value) {
      setCustomer((prevCustomer) => {
        const updatedCustomer = { ...prevCustomer, provinsi: value };
        dispatch(setCustomerData(updatedCustomer)); // Dispatch action
        return updatedCustomer;
      });
      fetchKota(value.id);
    }
  };

  const handleKotaSelect = async (event, value) => {
    if (value) {
      setCustomer((prevCustomer) => {
        const updatedCustomer = { ...prevCustomer, kota: value.name };
        dispatch(setCustomerData(updatedCustomer)); // Dispatch action
        return updatedCustomer;
      });
      setIsLoading(true);
      try {
        const response = await axios.get(
          "https://kasir.ajegjogja.com/api/alamat/kecamatan",
          {
            params: { kota_id: value.id },
          }
        );
        setKecamatanOptions(response.data);
      } catch (error) {
        console.error(
          "Error fetching kecamatan:",
          error.response ? error.response.data : error.message
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKecamatanSelect = async (event, value) => {
    if (value) {
      setCustomer((prevCustomer) => {
        const updatedCustomer = { ...prevCustomer, kecamatan: value.name };
        dispatch(setCustomerData(updatedCustomer)); // Dispatch action
        return updatedCustomer;
      });
      setIsLoading(true);
      try {
        const response = await axios.get(
          "https://kasir.ajegjogja.com/api/alamat/kelurahan",
          {
            params: { kecamatan_id: value.id },
          }
        );
        setKelurahanOptions(response.data);
      } catch (error) {
        console.error(
          "Error fetching kelurahan:",
          error.response ? error.response.data : error.message
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKelurahanSelect = (event, value) => {
    if (value) {
      setCustomer((prevCustomer) => {
        const updatedCustomer = { ...prevCustomer, kelurahan: value.name };
        dispatch(setCustomerData(updatedCustomer)); // Dispatch action
        return updatedCustomer;
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomer((prevCustomer) => {
      const updatedCustomer = { ...prevCustomer, [name]: value };
      dispatch(setCustomerData(updatedCustomer)); // Dispatch action
      return updatedCustomer;
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: 2,
        borderRadius: 2,
        boxShadow: 1,
        height: "100%",
        overflow: "auto",
      }}
    >
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Pelanggan Baru" />
        <Tab label="Pelanggan Lama" />
      </Tabs>
      <Divider sx={{ marginBottom: 2 }} />
      {tabValue === 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Sapaan</InputLabel>
                <Select
                  value={customer.title}
                  size="small"
                  onChange={(e) => handleInputChange(e)}
                  label="Sapaan"
                  name="title"
                >
                  <MenuItem value="Bapak">Bapak</MenuItem>
                  <MenuItem value="Ibu">Ibu</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Nama Lengkap"
                name="nama"
                size="small"
                value={customer.nama}
                onChange={(e) => handleInputChange(e)}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>

          <TextField
            fullWidth
            label="Nomor HP"
            size="small"
            name="nomorHp"
            type="tel"
            value={customer.nomorHp}
            onChange={(e) => handleInputChange(e)}
            sx={{ mb: 2 }}
          />
          <Autocomplete
            options={provinsiOptions}
            getOptionLabel={(option) => option.name}
            value={customer.provinsi} // Set default value
            isOptionEqualToValue={(option, value) => option.id === value.id} // Custom equality test
            onChange={handleProvinsiSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pilih Provinsi"
                variant="outlined"
                fullWidth
                size="small"
                sx={{ mb: 2 }}
              />
            )}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Autocomplete
                options={kotaOptions}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id} // Custom equality test
                onChange={handleKotaSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pilih Kota"
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ mb: 2 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={kecamatanOptions}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id} // Custom equality test
                onChange={handleKecamatanSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pilih Kecamatan"
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ mb: 2 }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Autocomplete
            options={kelurahanOptions}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id} // Custom equality test
            onChange={handleKelurahanSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pilih Kelurahan"
                variant="outlined"
                fullWidth
                size="small"
                sx={{ mb: 2 }}
              />
            )}
          />
          <TextField
            fullWidth
            label="Alamat Lengkap"
            size="small"
            name="alamat"
            multiline
            rows={3}
            value={customer.alamat}
            onChange={(e) => handleInputChange(e)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            size="small"
            type="date"
            label="Request Pengiriman"
            name="request_pengiriman"
            value={customer.request_pengiriman}
            onChange={(e) => handleInputChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Catatan"
            size="small"
            name="catatan"
            value={customer.catatan}
            onChange={(e) => handleInputChange(e)}
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />
        </>
      )}
      {tabValue === 1 && (
        <>
          {/* Form untuk pelanggan lama */}

          <Autocomplete
            options={existingCustomers}
            getOptionLabel={(option) => option.nama_pelanggan}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleExistingCustomerSelect}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.nama_pelanggan}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pilih Nama Pelanggan"
                variant="outlined"
                fullWidth
                size="small"
                sx={{ mb: 2 }}
              />
            )}
          />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Sapaan"
                name="title"
                size="small"
                value={customer.title}
                onChange={(e) => handleInputChange(e)}
                sx={{ mb: 2 }}
                disabled
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Nama Lengkap"
                name="nama"
                size="small"
                value={customer.nama}
                onChange={(e) => handleInputChange(e)}
                sx={{ mb: 2 }}
                disabled
              />
            </Grid>
          </Grid>
          <TextField
            fullWidth
            label="Nomor HP"
            size="small"
            name="nomorHp"
            type="tel"
            value={customer.nomorHp}
            onChange={(e) => handleInputChange(e)}
            sx={{ mb: 2 }}
            disabled
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Provinsi"
                size="small"
                name="provinsi"
                value={customer.provinsi.name}
                onChange={(e) => handleInputChange(e)}
                sx={{ mb: 2 }}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Kota"
                size="small"
                name="kota"
                value={customer.kota}
                onChange={(e) => handleInputChange(e)}
                sx={{ mb: 2 }}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Kecamatan"
                size="small"
                name="kecamatan"
                value={customer.kecamatan}
                onChange={(e) => handleInputChange(e)}
                sx={{ mb: 2 }}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Kelurahan"
                size="small"
                name="kelurahan"
                value={customer.kelurahan}
                onChange={(e) => handleInputChange(e)}
                sx={{ mb: 2 }}
                disabled
              />
            </Grid>
          </Grid>

          <TextField
            fullWidth
            label="Alamat Lengkap"
            size="small"
            name="alamat"
            multiline
            rows={3}
            value={customer.alamat}
            onChange={(e) => handleInputChange(e)}
            sx={{ mb: 2 }}
            disabled
          />
          <TextField
            fullWidth
            size="small"
            type="date"
            label="Request Pengiriman"
            name="request_pengiriman"
            value={customer.request_pengiriman}
            onChange={(e) => handleInputChange(e)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Catatan"
            size="small"
            name="catatan"
            value={customer.catatan}
            onChange={(e) => handleInputChange(e)}
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />
        </>
      )}
    </Box>
  );
};

export default CustomerData;
