import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import KayuForm from "./KayuForm";
import SofaForm from "./SofaForm";
import FabrikasiForm from "./FabrikasiForm";
import KomplimenForm from "./KomplimenForm";
import { closeModal } from "../slices/modalSlice";
import { addItem } from "../slices/orderSlice";
import { useMediaQuery, useTheme } from "@mui/material";

const ProductOrderModal = () => {
  const dispatch = useDispatch();
  const { open, product } = useSelector((state) => state.modal);
  const [totalAdditional, setTotalAdditional] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [formData, setFormData] = useState({});
  const [note, setNote] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [finishingVisible, setFinishingVisible] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const handleAdditionalChange = (total) => {
    setTotalAdditional(total);
  };

  const handleFormDataChange = (data) => {
    setFormData(data);
  };

  useEffect(() => {
    console.log("Product received in ProductOrderModal:", product);

    if (open) {
      // Reset form data when modal is opened or product is changed
      setTotalAdditional(0);
      setQuantity(1);
      setFormData({});
      setNote("");
      setValidationErrors({});
      setFinishingVisible(false);
    }
  }, [open, product]);

  if (!product) return null;

  const renderForm = () => {
    switch (product.id_jenis) {
      case 1:
        return (
          <SofaForm
            product={product}
            onAdditionalChange={handleAdditionalChange}
            onDataChange={handleFormDataChange}
            validationErrors={validationErrors}
            setFinishingVisible={setFinishingVisible}
          />
        );
      case 2:
        return (
          <KayuForm
            product={product}
            onAdditionalChange={handleAdditionalChange}
            onDataChange={handleFormDataChange}
            validationErrors={validationErrors}
          />
        );
      case 3:
        return (
          <FabrikasiForm
            product={product}
            onDataChange={handleFormDataChange}
          />
        );
      case 4:
        return (
          <KomplimenForm
            product={product}
            onAdditionalChange={handleAdditionalChange}
            onDataChange={handleFormDataChange}
            validationErrors={validationErrors}
          />
        );
      default:
        return null;
    }
  };

  const validateForm = () => {
    let errors = {};

    // Validation for SofaForm
    if (product.id_jenis === 1) {
      if (!formData.warna) {
        errors.warna = "Warna harus diisi.";
      }
      if (finishingVisible && !formData.finishing) {
        errors.finishing = "Finishing harus diisi.";
      }
      (formData.additionalOptions || []).forEach((option, index) => {
        if (option.jenis) {
          // Only validate if jenis is not empty
          if (!option.nilai) {
            errors[`additionalOptions[${index}].nilai`] = "Nilai harus diisi.";
          }
        }
      });
    }

    // Validation for KayuForm
    if (product.id_jenis === 2) {
      if (!formData.finishing) {
        errors.finishing = "Finishing harus diisi.";
      }
      (formData.additionalOptions || []).forEach((option, index) => {
        if (option.jenis) {
          // Only validate if jenis is not empty
          if (!option.nilai) {
            errors[`additionalOptions[${index}].nilai`] = "Nilai harus diisi.";
          }
        }
      });
    }

    // Validation for KomplimenForm
    if (product.id_jenis === 4) {
      if (!formData.warna) {
        errors.warna = "Warna harus diisi.";
      }
      if (finishingVisible && !formData.finishing) {
        errors.finishing = "Finishing harus diisi.";
      }
      (formData.additionalOptions || []).forEach((option, index) => {
        if (option.jenis) {
          // Only validate if jenis is not empty
          if (!option.nilai) {
            errors[`additionalOptions[${index}].nilai`] = "Nilai harus diisi.";
          }
        }
      });
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const totalHarga =
    (parseFloat(product.harga_jual) + totalAdditional) * quantity;

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleAddToOrder = () => {
    if (!validateForm()) {
      return;
    }

    const validAdditionalOptions = (formData.additionalOptions || []).filter(
      (option) => option.nilai
    );

    const orderItem = {
      ...product,
      quantity,
      totalAdditional,
      formData: {
        ...formData,
        additionalOptions: validAdditionalOptions,
      },
      note,
      totalHarga,
      isCustom: validAdditionalOptions.length > 0,
    };

    console.log("Order item:", orderItem);

    dispatch(addItem(orderItem));
    dispatch(closeModal());
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          maxHeight: 700,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: isMobile ? "95%" : 500,
          maxHeight: isMobile ? "90%" : 700,
          overflowY: "auto", // Add scroll if content overflows
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add order
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <IconButton
              aria-label="close"
              onClick={() => dispatch(closeModal())}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box
          display="flex"
          alignItems="center"
          mb={2}
          sx={{ backgroundColor: "#F8F9FD", p: 1, borderRadius: 2 }}
        >
          <Grid
            container
            spacing={1}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid item xs={8} display="flex">
              <img
                src={product.foto_produk}
                alt={product.nama}
                style={{
                  width: 50,
                  height: 50,
                  marginRight: 16,
                  borderRadius: 4,
                }}
              />
              <Box>
                <Typography sx={{ fontWeight: 600 }}>{product.nama}</Typography>
                <Typography>
                  Harga: {formatRupiah(product.harga_jual)}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              gap={1}
              sx={{ alignItems: "center" }}
            >
              <IconButton onClick={decrementQuantity}>
                <RemoveCircle />
              </IconButton>
              <Typography>{quantity}</Typography>
              <IconButton onClick={incrementQuantity} color="primary">
                <AddCircle />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1">Spesifikasi:</Typography>
          <Box
            component="ul"
            p={0}
            m={0}
            sx={{ border: "1px", borderRadius: 2 }}
          >
            {renderForm()}
          </Box>
        </Box>

        <TextField
          fullWidth
          label="Tambah Catatan"
          size="small"
          multiline
          rows={3}
          variant="outlined"
          placeholder="Type your note here..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
          sx={{ mt: 1 }}
        />
        <Box
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            container
            spacing={1}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid item xs={6}>
              <Typography>Total: {formatRupiah(totalHarga)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={handleAddToOrder}
              >
                Add to order
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductOrderModal;
