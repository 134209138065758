// path: src/features/orders/OrderRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import OrderPage from "./OrderPage";
import OrderDetailPage from "./OrderDetailPage";
import OrderPrint from "./OrderPrint";
import SettlementPage from "./SettlementPage";

const OrderRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<OrderPage />} />
      <Route
        path="/order-details/:transactionId"
        element={<OrderDetailPage />}
      />
      <Route path="/order-print" element={<OrderPrint />} />
      <Route
        path="/settlement/:transactionId"
        element={<SettlementPage />}
      />{" "}
      {/* Tambahkan Route untuk SettlementPage */}
    </Routes>
  );
};

export default OrderRoutes;
