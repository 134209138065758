import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  TextField,
  Snackbar,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import moment from "moment-timezone";
import {
  addTransaction,
  getLastTransactionNumber,
} from "../slices/transactionSlice";
import MarketingForm from "./MarketingForm";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Payment = ({
  subtotal,
  additionalCost,
  totalBeforeDiscount,
  discount,
  selectedPromotion,
  total,
  promotionId,
  promotionName,
  discountValue,
  shippingCost,
  tabValue,
}) => {
  const dispatch = useDispatch();
  const orderItems = useSelector((state) => state.order.items);
  const customerData = useSelector((state) => state.customer);
  const userInfo = useSelector((state) => state.user.userData);
  const userId = userInfo ? userInfo.id : null;
  const navigate = useNavigate();
  const [paymentAmount, setPaymentAmount] = useState("");
  const [changeAmount, setChangeAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Tunai");
  const [orderNumber, setOrderNumber] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [transactionData, setTransactionData] = useState(null);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  useEffect(() => {
    const fetchOrderNumber = async () => {
      const resultAction = await dispatch(getLastTransactionNumber()).unwrap();
      const number = generateOrderNumber(resultAction);
      setOrderNumber(number);
    };

    fetchOrderNumber();
  }, [dispatch]);

  const generateOrderNumber = (lastOrderNumber) => {
    if (!lastOrderNumber) {
      const now = new Date();
      const datePart = `${now.getDate().toString().padStart(2, "0")}${(
        now.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}${now.getFullYear().toString().slice(-2)}`;
      return `AJG-JOG-${datePart}-0001`;
    }

    const parts = lastOrderNumber.split("-");
    const lastNumber = parseInt(parts[3], 10);
    const newNumber = lastNumber + 1;
    const now = new Date();
    const datePart = `${now.getDate().toString().padStart(2, "0")}${(
      now.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}${now.getFullYear().toString().slice(-2)}`;
    const transactionNumberPart = newNumber.toString().padStart(4, "0");

    return `AJG-JOG-${datePart}-${transactionNumberPart}`;
  };

  const [marketingModalOpen, setMarketingModalOpen] = useState(false);
  const [marketingData, setMarketingData] = useState({
    tahuAjeg: "",
    closingDimana: "",
    statusPelanggan: "",
  });

  const handleMarketingChange = (e) => {
    const { name, value } = e.target;
    setMarketingData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handler function to show snackbar message
  const handleSnackbarMessage = (error) => {
    let errorMessage = "Terjadi kesalahan saat menyimpan transaksi";

    if (error.response && error.response.data) {
      // If error is from server response
      errorMessage = error.response.data.message || errorMessage;
    } else if (error.message) {
      // If error is a standard JavaScript error or Axios error
      errorMessage = error.message;
    } else {
      // If error is an unexpected format
      errorMessage = JSON.stringify(error);
    }

    showSnackbar(errorMessage, "error");
  };

  const handleProsesPesananClick = () => {
    setMarketingModalOpen(true); // Tampilkan modal marketing
  };

  const handleProsesPesanan = async () => {
    try {
      const transactionData = {
        user_id: userId,
        orderNumber,
        customer:
          tabValue === 1 ? { id: customerData.id } : { ...customerData },
        tanggal_transaksi: moment().tz("Asia/Jakarta").format(),
        additionalCost: additionalCost,
        total_harga: total,
        status_pembayaran:
          paymentAmount === formatRupiah(total) ? "Lunas" : "DP",
        tanggal_pengiriman: customerData.request_pengiriman,
        catatan: customerData.catatan,
        order_items: orderItems.map((item) => ({
          product_id: item.id_produk,
          jumlah: item.quantity,
          harga_satuan: item.harga_jual,
          subtotal: item.totalHarga,
          iscustom: item.isCustom,
          custom_charges: item.totalAdditional,
          id_warna: item.formData.id_warna,
          id_finishing: item.formData.id_finishing,
          finishing: item.formData.finishing,
          warna: item.formData.warna,
          formData: item.formData.additionalOptions,
          catatan: item.note,
          nama: item.nama,
          panjang: item.panjang,
          lebar: item.lebar,
          tinggi: item.tinggi,
          kain: item.kain,
          kaki: item.kaki,
          dudukan: item.dudukan,
          bantal_peluk: item.bantal_peluk,
          bantal_sandaran: item.bantal_sandaran,
          kantong_remot: item.kantong_remot,
          puff: item.puff,
        })),
        payment: {
          jumlah_pembayaran: parseInt(paymentAmount.replace(/[^0-9]/g, ""), 10),
          metode_pembayaran: paymentMethod,
          status_pembayaran:
            paymentAmount === formatRupiah(total) ? "Lunas" : "DP",
        },
        promotionId,
        promotionName,
        discountValue,
        shippingCost,
        tahu_ajeg: marketingData.tahuAjeg, // Data marketing
        closing_dimana: marketingData.closingDimana, // Data marketing
        status_pelanggan: marketingData.statusPelanggan, // Data marketing
      };

      console.log("Adding transaction to the database...", transactionData);

      const resultAction = await dispatch(
        addTransaction(transactionData)
      ).unwrap();
      console.log("Transaction successfully added:", resultAction);
      setTransactionData(resultAction); // Simpan data transaksi yang berhasil di state

      showSnackbar(
        resultAction.message || "Transaksi berhasil disimpan",
        "success"
      );
      navigate("/sales/print-receipt", { state: { transactionData } });
    } catch (error) {
      console.error("Error menyimpan transaksi:", error);
      handleSnackbarMessage(error);
    }
  };

  const handleKeyPress = (key) => {
    if (key === "C") {
      setPaymentAmount("");
      setChangeAmount("");
    } else {
      setPaymentAmount((prev) => {
        const numericValue = prev.replace(/[^0-9]/g, "") + key;
        const formattedValue = formatRupiah(numericValue);
        calculateChange(numericValue);
        return formattedValue;
      });
    }
  };

  const handleLunasClick = () => {
    const totalValue = total.toString();
    setPaymentAmount(formatRupiah(totalValue));
    calculateChange(totalValue);
  };

  const calculateChange = (payment) => {
    const paymentValue = parseInt(payment.replace(/[^0-9]/g, ""), 10);
    const changeValue = paymentValue - total;
    setChangeAmount(formatRupiah(changeValue.toString()));
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: 2,
        borderRadius: 2,
        boxShadow: 1,
        height: "100%",
        overflow: "auto",
      }}
    >
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Pembayaran
      </Typography>
      <Divider />
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={4}>
          <Typography variant="body2" component="div">
            No. Pesanan
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" component="div">
            : {orderNumber}
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ p: 2, backgroundColor: "#F8F9FD", borderRadius: 2, my: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" component="div">
              Sub Total
            </Typography>
            <Typography variant="body2" component="div">
              Charges
            </Typography>
            <Typography variant="body2" component="div">
              Ongkir
            </Typography>
            {selectedPromotion && (
              <Typography variant="body2" component="div">
                Hemat
              </Typography>
            )}
            <Typography variant="subtitle1" component="div">
              Total
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Typography variant="body2" component="div">
              {formatRupiah(subtotal)}
            </Typography>
            <Typography variant="body2" component="div">
              {formatRupiah(additionalCost)}
            </Typography>
            <Typography variant="body2" component="div">
              {formatRupiah(shippingCost)}
            </Typography>
            {selectedPromotion && (
              <Typography variant="body2" component="div">
                {formatRupiah(discount)}
              </Typography>
            )}
            <Typography variant="subtitle1" component="div">
              {formatRupiah(total)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Metode Pembayaran</Typography>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor: paymentMethod === "Tunai" ? "#3f51b5" : undefined,
                color: paymentMethod === "Tunai" ? "#3f51b5" : undefined,
              }}
              onClick={() => setPaymentMethod("Tunai")}
            >
              Tunai
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor:
                  paymentMethod === "Transfer" ? "#3f51b5" : undefined,
                color: paymentMethod === "Transfer" ? "#3f51b5" : undefined,
              }}
              onClick={() => setPaymentMethod("Transfer")}
            >
              Transfer
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor: paymentMethod === "Qris" ? "#3f51b5" : undefined,
                color: paymentMethod === "Qris" ? "#3f51b5" : undefined,
              }}
              onClick={() => setPaymentMethod("Qris")}
            >
              Qris
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                borderColor: paymentMethod === "Debit" ? "#3f51b5" : undefined,
                color: paymentMethod === "Debit" ? "#3f51b5" : undefined,
              }}
              onClick={() => setPaymentMethod("Debit")}
            >
              Debit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Status Pembayaran</Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              sx={{ borderColor: "#3f51b5", color: "#3f51b5" }}
              onClick={handleLunasClick}
            >
              Lunas
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              sx={{ borderColor: "#3f51b5", color: "#3f51b5" }}
            >
              DP
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          value={paymentAmount}
          placeholder="Masukkan jumlah pembayaran"
          InputProps={{
            readOnly: true,
          }}
        />
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          {changeAmount}
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Grid container spacing={1}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, "C", 0, "."].map((key) => (
            <Grid item xs={4} key={key}>
              <Button
                variant="outlined"
                fullWidth
                sx={{ borderColor: "#3f51b5", color: "#3f51b5" }}
                onClick={() => handleKeyPress(key)}
              >
                {key}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Button
        variant="contained"
        fullWidth
        size="large"
        onClick={handleProsesPesananClick}
      >
        Proses Pesanan
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <MarketingForm
        open={marketingModalOpen}
        handleClose={() => setMarketingModalOpen(false)}
        handleSubmit={handleProsesPesanan}
        marketingData={marketingData}
        handleMarketingChange={handleMarketingChange}
      />
    </Box>
  );
};

export default Payment;
