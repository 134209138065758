// path: src/features/sales/OrderModal/SofaForm.js
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  FormHelperText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const SofaForm = ({
  product,
  onAdditionalChange,
  onDataChange,
  validationErrors = {},
  setFinishingVisible,
}) => {
  const [additionalOptions, setAdditionalOptions] = useState([
    { jenis: "", nilai: "", harga: "" },
  ]);
  const [kainOptions, setKainOptions] = useState([]);
  const [kakiOptions, setKakiOptions] = useState([]);
  const [dudukanOptions, setDudukanOptions] = useState([]);
  const [warnaOptions, setWarnaOptions] = useState([]);
  const [finishingOptions, setFinishingOptions] = useState([]);
  const [selectedWarna, setSelectedWarna] = useState("");
  const [selectedWarnaId, setSelectedWarnaId] = useState("");
  const [selectedFinishing, setSelectedFinishing] = useState("");
  const [selectedFinishingId, setSelectedFinishingId] = useState("");

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const kainResponse = await fetch(
          "https://kasir.ajegjogja.com/api/options/kain"
        );
        const kakiResponse = await fetch(
          "https://kasir.ajegjogja.com/api/options/kaki"
        );
        const dudukanResponse = await fetch(
          "https://kasir.ajegjogja.com/api/options/dudukan"
        );

        if (!kainResponse.ok || !kakiResponse.ok || !dudukanResponse.ok) {
          throw new Error("Error fetching options");
        }

        const kainData = await kainResponse.json();
        const kakiData = await kakiResponse.json();
        const dudukanData = await dudukanResponse.json();

        console.log("Kain Data:", kainData);
        console.log("Kaki Data:", kakiData);
        console.log("Dudukan Data:", dudukanData);

        setKainOptions(kainData);
        setKakiOptions(kakiData);
        setDudukanOptions(dudukanData);
      } catch (error) {
        console.error("Fetching options failed:", error.message);
      }
    };

    if (product) {
      fetchOptions();
      fetchWarnaOptions(product.id_kain);
    }
  }, [product]);

  useEffect(() => {
    const fetchFinishingOptions = async () => {
      try {
        const response = await fetch(
          "https://kasir.ajegjogja.com/api/options/finishing"
        );
        if (!response.ok) {
          throw new Error("Error fetching finishing options");
        }
        const data = await response.json();
        setFinishingOptions(data);
      } catch (error) {
        console.error("Fetching finishing options failed:", error.message);
      }
    };

    fetchFinishingOptions();
  }, []);

  const fetchWarnaOptions = async (idKain) => {
    try {
      const response = await fetch(
        `https://kasir.ajegjogja.com/api/options/warna/${idKain}`
      );
      if (!response.ok) {
        throw new Error("Error fetching warna options");
      }
      const data = await response.json();
      setWarnaOptions(data);
    } catch (error) {
      console.error("Fetching warna options failed:", error.message);
    }
  };

  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const handleAdditionalChange = (index, field, value) => {
    const updatedOptions = [...additionalOptions];
    updatedOptions[index][field] = value;
    setAdditionalOptions(updatedOptions);
    updateTotalAdditional(updatedOptions);

    // Log untuk melihat kaki yang dipilih beserta namanya
    if (updatedOptions[index].jenis === "Kaki") {
      const selectedKakiOption = kakiOptions.find(
        (option) => option.id_kaki === updatedOptions[index].nilai
      );
      console.log(
        "Kaki yang dipilih:",
        selectedKakiOption ? selectedKakiOption.jenis_kaki : "Tidak ditemukan"
      );
    }

    if (field === "jenis" && value === "Kain") {
      fetchWarnaOptions(updatedOptions[index].nilai);
    }

    console.log(
      `Jenis: ${updatedOptions[index].jenis}, Nilai: ${updatedOptions[index].nilai}`
    );

    onDataChange({
      additionalOptions: updatedOptions,
      warna: selectedWarna,
      id_warna: selectedWarnaId,
      finishing: selectedFinishing,
      id_finishing: selectedFinishingId,
    });
    setFinishingVisible(isFinishingVisible(updatedOptions));
  };

  const handleWarnaChange = (value, id) => {
    setSelectedWarna(value);
    setSelectedWarnaId(id);
    onDataChange({
      additionalOptions,
      warna: value,
      id_warna: id,
      finishing: selectedFinishing,
      id_finishing: selectedFinishingId,
    });
  };

  const handleFinishingChange = (value, id) => {
    setSelectedFinishing(value);
    setSelectedFinishingId(id);
    onDataChange({
      additionalOptions,
      warna: selectedWarna,
      id_warna: selectedWarnaId,
      finishing: value,
      id_finishing: id,
    });
  };

  const updateTotalAdditional = (options) => {
    const totalAdditional = options.reduce((acc, option) => {
      const harga = parseFloat(option.harga) || 0;
      return acc + harga;
    }, 0);
    console.log("SofaForm mengirim total additional:", totalAdditional);
    onAdditionalChange(totalAdditional);
  };

  const handleHargaChange = (index, value) => {
    const formattedValue = value.replace(/[^\d]/g, "");
    const numericValue = formattedValue ? parseFloat(formattedValue) : 0;
    handleAdditionalChange(index, "harga", numericValue);
  };

  const addAdditionalOption = () => {
    setAdditionalOptions([
      ...additionalOptions,
      { jenis: "", nilai: "", harga: "" },
    ]);
  };

  const removeAdditionalOption = (index) => {
    const optionToRemove = additionalOptions[index];
    const updatedOptions = additionalOptions.filter((_, i) => i !== index);
    setAdditionalOptions(updatedOptions);
    updateTotalAdditional(updatedOptions);

    if (optionToRemove.jenis === "Kain") {
      fetchWarnaOptions(product.id_kain);
    }
    setFinishingVisible(isFinishingVisible(updatedOptions));
  };

  const clearAllAdditionalOptions = () => {
    setAdditionalOptions([{ jenis: "", nilai: "", harga: "" }]);
    updateTotalAdditional([]);
    setFinishingVisible(false);
  };

  const getOptionsForJenis = (jenis) => {
    switch (jenis) {
      case "Kain":
        return kainOptions.map((option) => ({
          value: option.id_kain,
          label: option.kain,
        }));
      case "Kaki":
        return kakiOptions.map((option) => ({
          value: option.id_kaki,
          label: option.jenis_kaki,
        }));
      case "Dudukan":
        return dudukanOptions.map((option) => ({
          value: option.id_dudukan,
          label: option.dudukan,
        }));
      default:
        return [];
    }
  };

  const totalAdditional = additionalOptions.reduce((acc, option) => {
    const harga = parseFloat(option.harga) || 0;
    return acc + harga;
  }, 0);

  const selectedKain =
    additionalOptions.find((option) => option.jenis === "Kain")?.nilai ||
    product.kain;

  useEffect(() => {
    if (selectedKain) {
      fetchWarnaOptions(selectedKain);
    }
  }, [selectedKain]);

  const isFinishingVisible = (options) => {
    console.log("Options:", options); // Log semua options untuk verifikasi

    // Mencari opsi kaki berdasarkan id_kaki dalam additionalOptions
    const legOption = options.find((option) => option.jenis === "Kaki");

    // Mengambil nilai kaki atau menggunakan nilai default dari produk
    const legValue = legOption ? legOption.nilai : null;

    // Mencari nama kaki berdasarkan id_kaki
    const selectedKakiOption = legValue
      ? kakiOptions.find((option) => option.id_kaki === legValue)
      : null;
    const legNameFromOptions = selectedKakiOption
      ? selectedKakiOption.jenis_kaki
      : null;

    // Gunakan nama kaki dari additionalOptions jika ada, jika tidak gunakan product.kaki
    const legName = legNameFromOptions || product.kaki;

    // Tambahan console log untuk debugging
    console.log("Leg Option:", legOption);
    console.log("Leg Value (id_kaki):", legValue);
    console.log("Leg Name from Options:", legNameFromOptions);
    console.log("Product Kaki:", product.kaki);
    console.log("Leg Name:", legName); // Nama kaki yang digunakan untuk pengecekan

    // Pemeriksaan apakah nama kaki sesuai dengan yang diharapkan
    const finishingVisibleLegs = ["Balok Kayu", "Skandinavian"];
    const isVisible = finishingVisibleLegs.includes(legName);

    // Log hasil akhir
    console.log("Is Finishing Visible:", isVisible);

    return isVisible;
  };

  useEffect(() => {
    setFinishingVisible(isFinishingVisible(additionalOptions));
  }, [additionalOptions]);

  return (
    <Box sx={{ maxHeight: 350, overflowY: "scroll" }}>
      <Grid container spacing={1}>
        <Grid item container spacing={1} xs={6}>
          <Grid item xs={4}>
            <Typography variant="body2">Dimensi</Typography>
            <Typography variant="body2">Kain</Typography>
            <Typography variant="body2">Kaki</Typography>
            <Typography variant="body2">Dudukan</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">
              : {product.panjang} x {product.lebar} x {product.tinggi} cm
            </Typography>
            <Typography variant="body2">: {product.kain}</Typography>
            <Typography variant="body2">: {product.kaki}</Typography>
            <Typography variant="body2">: {product.dudukan}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={6}>
          <Grid item xs={8}>
            <Typography variant="body2">Bantal Peluk</Typography>
            <Typography variant="body2">Bantal Sandaran</Typography>
            <Typography variant="body2">Kantong Remote</Typography>
            <Typography variant="body2">Puff</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">: {product.bantal_peluk}</Typography>
            <Typography variant="body2">: {product.bantal_sandaran}</Typography>
            <Typography variant="body2">: {product.kantong_remot}</Typography>
            <Typography variant="body2">
              : {product.puff ? "Ya" : "Tidak"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
            Additional Charges / Custom:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
            {formatRupiah(totalAdditional)}
          </Typography>
        </Grid>
      </Grid>
      <Box
        component="ul"
        p={1}
        m={0}
        sx={{ border: "1px solid #ccc", borderRadius: 2, mt: 1 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
              Jenis
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
              Nilai
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
              Harga
            </Typography>
          </Grid>
          {additionalOptions.map((option, index) => (
            <React.Fragment key={index}>
              <Grid item xs={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id={`jenis-label-${index}`}>
                    Pilih Jenis
                  </InputLabel>
                  <Select
                    labelId={`jenis-label-${index}`}
                    value={option.jenis}
                    label="Pilih Jenis"
                    onChange={(event) =>
                      handleAdditionalChange(index, "jenis", event.target.value)
                    }
                  >
                    <MenuItem value="Dimensi">Dimensi</MenuItem>
                    <MenuItem value="Kain">Kain</MenuItem>
                    <MenuItem value="Kaki">Kaki</MenuItem>
                    <MenuItem value="Dudukan">Dudukan</MenuItem>
                    <MenuItem value="Bantal Peluk">Bantal Peluk</MenuItem>
                    <MenuItem value="Bantal Sandaran">Bantal Sandaran</MenuItem>
                    <MenuItem value="Kantong Remote">Kantong Remote</MenuItem>
                    <MenuItem value="Puff">Puff</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                {[
                  "Dimensi",
                  "Bantal Peluk",
                  "Bantal Sandaran",
                  "Kantong Remote",
                  "Puff",
                ].includes(option.jenis) ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={option.nilai}
                    onChange={(event) =>
                      handleAdditionalChange(index, "nilai", event.target.value)
                    }
                    placeholder="Masukkan nilai"
                  />
                ) : (
                  <FormControl
                    fullWidth
                    size="small"
                    error={
                      !!validationErrors[`additionalOptions[${index}].nilai`]
                    }
                  >
                    <InputLabel id={`nilai-label-${index}`}>
                      Pilih Nilai
                    </InputLabel>
                    <Select
                      labelId={`nilai-label-${index}`}
                      value={option.nilai}
                      label="Pilih Nilai"
                      onChange={(event) =>
                        handleAdditionalChange(
                          index,
                          "nilai",
                          event.target.value
                        )
                      }
                    >
                      {getOptionsForJenis(option.jenis).map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {validationErrors[`additionalOptions[${index}].nilai`] && (
                      <FormHelperText>
                        {validationErrors[`additionalOptions[${index}].nilai`]}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={4} display="flex">
                <TextField
                  fullWidth
                  size="small"
                  value={option.harga}
                  onChange={(event) =>
                    handleHargaChange(index, event.target.value)
                  }
                  placeholder="Masukkan harga"
                />
                <IconButton
                  onClick={() => removeAdditionalOption(index)}
                  color="secondary"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              onClick={addAdditionalOption}
              variant="contained"
              fullWidth
              sx={{ mt: 1 }}
            >
              Tambah Pilihan
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={clearAllAdditionalOptions}
              variant="outlined"
              fullWidth
              sx={{ mt: 1 }}
            >
              Hapus Semua
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!validationErrors.warna}
            >
              <InputLabel id="pilihan-warna">Pilih Warna</InputLabel>
              <Select
                labelId="pilihan-warna"
                value={selectedWarna}
                label="Pilih Warna"
                onChange={(e) => {
                  const selectedOption = warnaOptions.find(
                    (option) => option.warna === e.target.value
                  );
                  handleWarnaChange(e.target.value, selectedOption.id_warna);
                }}
              >
                {warnaOptions.map((warna) => (
                  <MenuItem key={warna.id_warna} value={warna.warna}>
                    {warna.warna}
                  </MenuItem>
                ))}
              </Select>
              {validationErrors.warna && (
                <Typography color="error" variant="body2">
                  {validationErrors.warna}
                </Typography>
              )}
            </FormControl>
          </Grid>
          {isFinishingVisible(additionalOptions) && (
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                error={!!validationErrors.finishing}
              >
                <InputLabel id="pilihan-finishing">Finishing</InputLabel>
                <Select
                  labelId="pilihan-finishing"
                  value={selectedFinishing}
                  label="Finishing"
                  onChange={(e) => {
                    const selectedOption = finishingOptions.find(
                      (option) => option.finishing === e.target.value
                    );
                    handleFinishingChange(
                      e.target.value,
                      selectedOption.id_finishing
                    );
                  }}
                >
                  {finishingOptions.map((finishing) => (
                    <MenuItem
                      key={finishing.id_finishing}
                      value={finishing.finishing}
                    >
                      {finishing.finishing}
                    </MenuItem>
                  ))}
                </Select>
                {validationErrors.finishing && (
                  <Typography color="error" variant="body2">
                    {validationErrors.finishing}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default SofaForm;
