// features/sales/OrderConfirmation/OrderConfirmationPage.js
import React from "react";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import OrderDetails from "./OrderDetails";
import CustomerData from "./CustomerData";
import Payment from "./Payment";
import theme from "../../../theme";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";

const OrderConfirmationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    subtotal,
    additionalCost,
    totalBeforeDiscount,
    discount,
    selectedPromotion,
    total,
    promotionId, // Tambahkan promotionId
    promotionName, // Tambahkan promotionName
    discountValue, // Tambahkan discountValue
    shippingCost, // Tambahkan shippingCost
  } = location.state || {};

  const handleBack = () => {
    window.history.back();
  };

  return (
    <Box>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          color: theme.palette.primary.main,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleBack}
            sx={{ marginRight: 2, borderRadius: "50%", border: "1px solid" }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Konfirmasi Pesanan
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          padding: 2,
          backgroundImage: "radial-gradient(circle, #EEDFED, #E7EFFD)",
          minHeight: "93vh",
          height: "90vh", // memastikan tinggi penuh layar
          overflow: "auto",
        }}
      >
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item xs={12} md={5} sx={{ height: "100%" }}>
            <Box sx={{ height: "100%", overflowY: "auto" }}>
              <OrderDetails />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ height: "100%" }}>
            <Box sx={{ height: "100%", overflowY: "auto" }}>
              {" "}
              <CustomerData />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Payment
              subtotal={subtotal}
              additionalCost={additionalCost}
              totalBeforeDiscount={totalBeforeDiscount}
              selectedPromotion={selectedPromotion}
              discount={discount}
              total={total}
              promotionId={promotionId}
              promotionName={promotionName}
              discountValue={discountValue}
              shippingCost={shippingCost}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderConfirmationPage;
