// path: features/dashboard/reportingSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";

export const fetchReportingData = createAsyncThunk(
  "reporting/fetchReportingData",
  async () => {
    try {
      const response = await axiosInstance.get("/api/reporting/transactions");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSettlementData = createAsyncThunk(
  "reporting/fetchSettlementData",
  async () => {
    try {
      const response = await axiosInstance.get("/api/reporting/settlement");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const reportingSlice = createSlice({
  name: "reporting",
  initialState: {
    data: [],
    status: "idle",
    error: null,

    settlementData: [],
    settlementStatus: "idle",
    settlementError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportingData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReportingData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchReportingData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSettlementData.pending, (state) => {
        state.settlementStatus = "loading";
      })
      .addCase(fetchSettlementData.fulfilled, (state, action) => {
        state.settlementStatus = "succeeded";
        state.settlementData = action.payload;
      })
      .addCase(fetchSettlementData.rejected, (state, action) => {
        state.settlementStatus = "failed";
        state.settlementError = action.error.message;
      });
  },
});

export default reportingSlice.reducer;
