// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider, useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import LoginPage from "./features/Auth/LoginPage";
import RegisterPage from "./features/Auth/RegisterPage";
import PINPage from "./features/Auth/PinPage";
import MenuPage from "./features/Menu/MenuPage";
import SalesRoutes from "./features/Sales/SalesRoutes";
import Dashboard from "./features/dashboard/Dashboard"; // Import Dashboard
import PromoRoutes from "./features/promotions/promoRoutes";
import ProductRoutes from "./features/products/productRoutes";
import OrderRoutes from "./features/orders/OrderRoutes";
import CustomerRoutes from "./features/Customers/CustomerRoutes";
import { verifyToken, logoutUser } from "./actions/userActions"; // Pastikan ini benar

import NotaPage from "./features/Sales/NotaPage";

import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";

import store from "./store/store";

// Ini adalah komponen yang menentukan route berdasarkan status autentikasi
const AuthRoutes = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const currentPath = window.location.pathname; // Tambahkan ini untuk tracking path

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        console.log("Token expired, logging out...");
        localStorage.removeItem("token");
        dispatch(logoutUser());
        if (!currentPath.startsWith("/daftarajeg")) {
          window.location.href = "/login";
        }
      } else {
        dispatch(verifyToken());
      }
    } else {
      console.log("No token found");
      if (
        !currentPath.startsWith("/daftarajeg") &&
        !isAuthenticated &&
        currentPath !== "/login"
      ) {
        window.location.href = "/login";
      }
    }
  }, [dispatch, isAuthenticated, currentPath]); // Tambahkan `isAuthenticated` dan `currentPath` sebagai dependencies

  return (
    <Router>
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route path="/" element={<LoginPage />} />
            <Route path="/daftarajeg" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/nota/:orderId" element={<NotaPage />} />{" "}
            <Route path="*" element={<LoginPage />} />
          </>
        ) : (
          <>
            <Route path="/pin" element={<PINPage />} />
            <Route path="/menu" element={<MenuPage />} />
            <Route path="/sales/*" element={<SalesRoutes />} />
            <Route path="/promotions/*" element={<PromoRoutes />} />
            <Route path="/products/*" element={<ProductRoutes />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/orders/*" element={<OrderRoutes />} />
            <Route path="/customers/*" element={<CustomerRoutes />} />
            <Route path="*" element={<MenuPage />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

// Komponen App adalah root dari aplikasi yang mengatur Provider untuk Redux dan ThemeProvider untuk MUI
const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthRoutes />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
