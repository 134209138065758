// path: src/features/products/productRoutes/index.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import ProductPage from "../ProductPage";
import AddProduct from "../AddProduct";

const ProductRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProductPage />} />
      <Route path="/add" element={<AddProduct />} />
    </Routes>
  );
};

export default ProductRoutes;
