import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import OrderDetails from "./OrderDetails";
import { format } from "date-fns";
import formatRupiah from "../../../utils/formatRupiah";

const statusStyles = {
  pending: { backgroundColor: "#34495e", color: "white" },
  povendor: { backgroundColor: "#9b59b6", color: "white" },
  siapdikirim: { backgroundColor: "#54a0ff", color: "white" },
  terkirim: { backgroundColor: "#30cb83", color: "white" },
  dibatalkan: { backgroundColor: "#b33771", color: "white" },
  return: { backgroundColor: "#e74c3c", color: "white" },
  default: { backgroundColor: "#d3d3d3", color: "black" },
};

const OrderItem = ({ order, index }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd-MM-yy");
  };

  // Mengambil transaction_id dari order.details
  const transactionId =
    order.details.length > 0 ? order.details[0].transaction_id : null;

  return (
    <>
      <TableRow>
        <TableCell>{index}</TableCell>
        <TableCell>
          <Typography variant="body2">
            {formatDate(order.tanggal_transaksi)}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {order.order_number}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{order.sales_name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {formatRupiah(order.total_harga)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{order.nama_pelanggan}</Typography>
          <Typography variant="body2" color="textSecondary">
            {order.nomor_hp}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">
            {formatDate(order.tanggal_pengiriman)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {order.status_pembayaran} : {formatRupiah(order.jumlah_pembayaran)}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {order.metode_pembayaran}
          </Typography>
        </TableCell>
        <TableCell>
          <IconButton onClick={handleToggle}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <OrderDetails
              details={order.details}
              transactionId={transactionId}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OrderItem;
