// src/reducers/index.js
import { combineReducers } from "redux";
import authReducer from "../features/Auth/authSlice"; // Pastikan jalur ini benar
import userReducer from "./userReducer"; // Pastikan jalur ini benar
import modalReducer from "../features/Sales/slices/modalSlice"; // Pastikan jalur ini benar
import orderReducer from "../features/Sales/slices/orderSlice"; // Pastikan jalur ini benar
import customerReducer from "../features/Sales/slices/customerSlice"; // Pastikan jalur ini benar
import productReducer from "../features/products/productSlice"; // Pastikan jalur ini benar
import reportingReducer from "../features/dashboard/reportingSlice"; // Pastikan jalur ini benar
import ordersReducer from "../features/orders/ordersSlice"; // Pastikan ini benar, perbaiki huruf kapital jika perlu
import promotionSlice from "../features/promotions/promotionSlice";
import transactionSlice from "../features/Sales/slices/transactionSlice";
import customerSlice from "../features/Customers/customerSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  modal: modalReducer,
  order: orderReducer,
  customer: customerReducer,
  products: productReducer,
  reporting: reportingReducer,
  orders: ordersReducer,
  promotions: promotionSlice,
  transactions: transactionSlice,
  customers: customerSlice,
});

export default rootReducer;
