// path: src/features/Sales/SalesRoutes/index.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import PromotionList from "../PromotionList";

const PromoRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PromotionList />} />
    </Routes>
  );
};

export default PromoRoutes;
