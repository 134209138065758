import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Typography,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";
import OrderItemsSummary from "./OrderItemsSummary";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DiscountIcon from "@mui/icons-material/Discount";
import CloseIcon from "@mui/icons-material/Close";
import PromoModal from "./PromoModal/PromoModal";
import { fetchPromotions } from "../promotions/promotionSlice";

const OrderSummary = () => {
  const dispatch = useDispatch();
  const promotions = useSelector((state) => state.promotions.promotions);
  const orderItems = useSelector((state) => state.order.items);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [shippingCost, setShippingCost] = useState(0);
  const navigate = useNavigate();
  const [openPromoModal, setOpenPromoModal] = useState(false);

  const handleOpenPromoModal = () => setOpenPromoModal(true);
  const handleClosePromoModal = () => setOpenPromoModal(false);

  useEffect(() => {
    dispatch(fetchPromotions());
  }, [dispatch]);

  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  // Tambahkan fungsi untuk menghapus promosi
  const handleRemovePromotion = () => {
    setSelectedPromotion(null);
  };

  const subtotal = orderItems.reduce(
    (total, item) => total + item.harga_jual * item.quantity,
    0
  );

  const additionalCost = orderItems.reduce(
    (total, item) => total + item.totalAdditional,
    0
  );

  const totalBeforeDiscount = subtotal + additionalCost;

  // Fungsi untuk menghitung diskon
  let discount = 0;
  let discountInfo = "";
  if (selectedPromotion) {
    if (selectedPromotion.promotion_type === "percentage") {
      discount = (totalBeforeDiscount * selectedPromotion.discount_value) / 100;
      discountInfo = `${selectedPromotion.promotion_name} | ${
        selectedPromotion.discount_value
      }% | Rp ${formatRupiah(discount)}`;
    } else if (selectedPromotion.promotion_type === "nominal") {
      discount = selectedPromotion.discount_value;
      discountInfo = `${selectedPromotion.promotion_name} | Rp ${formatRupiah(
        selectedPromotion.discount_value
      )}`;
    }
  }

  const total = totalBeforeDiscount - discount + parseFloat(shippingCost);

  const handleNext = () => {
    navigate("/sales/order-confirmation", {
      state: {
        subtotal,
        additionalCost,
        totalBeforeDiscount,
        discount,
        selectedPromotion,
        promotionId: selectedPromotion ? selectedPromotion.id : null,
        promotionName: selectedPromotion
          ? selectedPromotion.promotion_name
          : null,
        discountValue: discount,
        shippingCost,
        total,
      },
    });
  };

  // Filter promosi berdasarkan subtotal
  const eligiblePromotions = promotions.filter(
    (promotion) => totalBeforeDiscount >= promotion.minimum_purchase
  );

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", height: "100vh", pt: 8 }}
    >
      <Box sx={{ p: 2 }}>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ fontWeight: "600", fontSize: "16px" }}
        >
          Ringkasan Pesanan
        </Typography>
      </Box>
      <Box sx={{ borderTop: "1px solid #ccc", overflow: "auto" }}>
        <OrderItemsSummary />
      </Box>

      <Box sx={{ p: 2, borderTop: "1px solid #ccc", mt: "auto" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ fontWeight: "600", fontSize: "14px" }}
          >
            Sub Total
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ fontWeight: "600", fontSize: "14px" }}
          >
            {formatRupiah(subtotal)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ fontWeight: "600", fontSize: "14px" }}
          >
            Custom Charges
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ fontWeight: "600", fontSize: "14px" }}
          >
            {formatRupiah(additionalCost)}
          </Typography>
        </Box>
        {selectedPromotion && (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "600", fontSize: "14px" }}
            >
              Harga Sebelum Diskon
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontWeight: "600", fontSize: "14px" }}
            >
              {formatRupiah(totalBeforeDiscount)}
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenPromoModal}
            startIcon={<DiscountIcon />}
            fullWidth
          >
            Promo
          </Button>
          {/* Modal promosi */}
          <PromoModal
            open={openPromoModal}
            onClose={handleClosePromoModal}
            promotions={promotions}
            totalBeforeDiscount={totalBeforeDiscount}
            onSelect={setSelectedPromotion}
          />
          {/* Informasi promosi yang digunakan */}
          {selectedPromotion && (
            <Box sx={{ mt: 2 }}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="body1">Promo: {discountInfo}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    size="small"
                    onClick={handleRemovePromotion}
                    sx={{ ml: 1 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>

        <Box sx={{ mt: 2 }}>
          <TextField
            label="Ongkir"
            type="number"
            fullWidth
            size="small"
            value={shippingCost}
            onChange={(e) => setShippingCost(e.target.value)}
            InputProps={{
              startAdornment: (
                <Typography variant="body1" sx={{ mr: 1 }}>
                  Rp
                </Typography>
              ),
            }}
          />
        </Box>

        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6" component="div">
            Total
          </Typography>
          <Typography variant="h6" component="div">
            {formatRupiah(total)}
          </Typography>
        </Box>
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={handleNext}
          disabled={orderItems.length === 0} // Disable button if no items
        >
          Selanjutnya
        </Button>
      </Box>
    </Box>
  );
};

export default OrderSummary;
