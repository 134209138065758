import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  ButtonGroup,
  TextField,
  CircularProgress,
  InputAdornment,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

const ProductBar = ({ onCategorySelect, onSearch, onViewChange, view }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeCategory, setActiveCategory] = useState("All");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        // Mengambil token dari local storage atau dari mana pun yang Anda simpan
        const token = localStorage.getItem("token");

        // Menetapkan Authorization header ke setiap permintaan Axios
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const response = await axios.get(
          "https://kasir.ajegjogja.com/api/products/"
        );
        const uniqueCategories = new Set();
        response.data.forEach((product) => {
          if (product.kategori && !uniqueCategories.has(product.kategori)) {
            uniqueCategories.add(product.kategori);
          }
        });
        setCategories(["All", ...Array.from(uniqueCategories)]);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
      setLoading(false);
    };

    fetchCategories();
  }, []);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onSearch(query);
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    onCategorySelect(category === "All" ? "" : category);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        justifyContent: "space-between",
        padding: 2,
        alignItems: "center",
        gap: 2,
        backgroundColor: "background.default",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            size="small"
            placeholder="Search products"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { borderRadius: "8px" },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              sx={{ flexWrap: isSmallScreen ? "wrap" : "nowrap" }}
            >
              {categories.map((category, index) => (
                <Button
                  key={index}
                  onClick={() => handleCategoryClick(category)}
                  sx={{
                    bgcolor:
                      activeCategory === category ? "primary.main" : "inherit",
                    color:
                      activeCategory === category ? "common.white" : "inherit",
                    "&:hover": {
                      bgcolor:
                        activeCategory === category ? "primary.dark" : "",
                    },
                  }}
                >
                  {category}
                </Button>
              ))}
            </ButtonGroup>
          )}
        </Grid>
      </Grid>

      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={onViewChange}
        aria-label="view"
        sx={{ mt: isSmallScreen ? 2 : 0 }}
      >
        <ToggleButton value="list" aria-label="list view">
          <ViewListIcon />
        </ToggleButton>
        <ToggleButton value="card" aria-label="card view">
          <ViewModuleIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ProductBar;
