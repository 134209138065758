// features/Sales/CategoryBar.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  ButtonGroup,
  TextField,
  CircularProgress,
  Grid,
  InputAdornment,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../products/productSlice";

const CategoryBar = ({ onCategorySelect, onSearch }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeCategory, setActiveCategory] = useState("All");
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (products.length > 0) {
      const uniqueCategories = new Set();
      products.forEach((product) => {
        if (product.kategori && !uniqueCategories.has(product.kategori)) {
          uniqueCategories.add(product.kategori);
        }
      });
      setCategories(["All", ...Array.from(uniqueCategories)]);
    }
  }, [products]);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Memanggil fungsi onSearch dengan query sebagai argumen
    onSearch(query);
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    onCategorySelect(category === "All" ? "" : category);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: 2,
        alignItems: "center",
        gap: 2,
        backgroundColor: "background.default",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size="small"
            placeholder="Search products"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { borderRadius: "8px" }, // Adjust the radius as needed
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Box
              sx={{
                display: "flex",
                overflowX: "auto",
                scrollbarWidth: "thin",
              }}
            >
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                {categories.map((category, index) => (
                  <Button
                    key={index}
                    onClick={() => handleCategoryClick(category)}
                    sx={{
                      bgcolor:
                        activeCategory === category
                          ? "primary.main"
                          : "inherit",
                      color:
                        activeCategory === category
                          ? "common.white"
                          : "inherit",
                      "&:hover": {
                        bgcolor:
                          activeCategory === category ? "primary.dark" : "",
                      },
                    }}
                  >
                    {category}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CategoryBar;
