// path: src/features/orders/components/PrintThermal.js
import React from "react";
import { Box, Typography, Divider, Grid } from "@mui/material";
import { ArrowBack, Instagram, Language, Print } from "@mui/icons-material";
import theme from "../../../theme";

const PrintThermal = ({ orderDetails, userName }) => {
  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const maskPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const length = phoneNumber.length;
    if (length <= 4) return "XXXX";
    return phoneNumber.slice(0, -4) + "XXXX";
  };

  const calculateSubtotal = () => {
    const itemsSubtotal = orderDetails.details.reduce(
      (total, item) => total + parseFloat(item.subtotal || 0),
      0
    );
    return itemsSubtotal;
  };

  const calculateRemainingBalance = () => {
    const total = orderDetails.total_harga;
    const paid = orderDetails.jumlah_pembayaran;
    return total - paid;
  };

  if (!orderDetails) {
    return <Typography>No transaction data available</Typography>;
  }

  const renderCustomizations = (item) => {
    const { custom_details, warna, finishing } = item;

    if (custom_details && custom_details.length > 0) {
      console.log("Custom Details:", custom_details); // Log custom details

      return (
        <Box>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mt: 1, fontWeight: 600 }}
          >
            Custom Details
          </Typography>
          <Grid container>
            {custom_details.map((detail, index) => (
              <React.Fragment key={index}>
                {detail.dimensi && (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Dimensi
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        : {detail.dimensi}
                      </Typography>
                    </Grid>
                  </>
                )}
                {detail.jenis_kain && (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Kain
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        : {detail.jenis_kain}
                      </Typography>
                    </Grid>
                  </>
                )}
                {detail.jenis_kaki && (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Kaki
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        : {detail.jenis_kaki}
                      </Typography>
                    </Grid>
                  </>
                )}
                {detail.jenis_dudukan && (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Dudukan
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        : {detail.jenis_dudukan}
                      </Typography>
                    </Grid>
                  </>
                )}
                {detail.custom_charges && (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Custom Charges
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        : {formatRupiah(detail.custom_charges)}
                      </Typography>
                    </Grid>
                  </>
                )}
              </React.Fragment>
            ))}
            {warna && (
              <>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    Warna
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    : {warna}
                  </Typography>
                </Grid>
              </>
            )}
            {finishing && (
              <>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    Finishing
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    : {finishing}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      );
    } else {
      return (
        <Grid container>
          {warna && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">
                  Warna
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  : {warna}
                </Typography>
              </Grid>
            </>
          )}
          {finishing && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">
                  Finishing
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  : {finishing}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      );
    }
  };

  const renderSpecifications = (item) => {
    const sofaDetail = item.sofa_details ? item.sofa_details[0] : null;

    return (
      <Grid container spacing={1}>
        <Grid item xs={4}>
          {(item.panjang || item.lebar || item.tinggi) && (
            <Typography variant="body2">Dimensi</Typography>
          )}
          {sofaDetail && sofaDetail.kain && (
            <Typography variant="body2">Kain</Typography>
          )}
          {sofaDetail && sofaDetail.jenis_kaki && (
            <Typography variant="body2">Kaki</Typography>
          )}
          {sofaDetail && sofaDetail.dudukan && (
            <Typography variant="body2">Dudukan</Typography>
          )}
          {sofaDetail && sofaDetail.bantal_peluk !== null && (
            <Typography variant="body2">B Peluk</Typography>
          )}
          {sofaDetail && sofaDetail.bantal_sandaran !== null && (
            <Typography variant="body2">B Sandaran</Typography>
          )}
          {sofaDetail && sofaDetail.kantong_remot !== null && (
            <Typography variant="body2">K Remot</Typography>
          )}
          {sofaDetail && sofaDetail.puff !== null && (
            <Typography variant="body2">Puff</Typography>
          )}
        </Grid>
        <Grid item xs={8}>
          {(item.panjang || item.lebar || item.tinggi) && (
            <Typography variant="body2">
              : {item.panjang || "-"} x {item.lebar || "-"} x{" "}
              {item.tinggi || "-"} cm
            </Typography>
          )}
          {sofaDetail && sofaDetail.kain && (
            <Typography variant="body2">: {sofaDetail.kain}</Typography>
          )}
          {sofaDetail && sofaDetail.jenis_kaki && (
            <Typography variant="body2">: {sofaDetail.jenis_kaki}</Typography>
          )}
          {sofaDetail && sofaDetail.dudukan && (
            <Typography variant="body2">: {sofaDetail.dudukan}</Typography>
          )}
          {sofaDetail && sofaDetail.bantal_peluk !== null && (
            <Typography variant="body2">: {sofaDetail.bantal_peluk}</Typography>
          )}
          {sofaDetail && sofaDetail.bantal_sandaran !== null && (
            <Typography variant="body2">
              : {sofaDetail.bantal_sandaran}
            </Typography>
          )}
          {sofaDetail && sofaDetail.kantong_remot !== null && (
            <Typography variant="body2">
              : {sofaDetail.kantong_remot}
            </Typography>
          )}
          {sofaDetail && sofaDetail.puff !== null && (
            <Typography variant="body2">
              : {sofaDetail.puff ? "Ya" : "Tidak"}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      className="printable-content"
      id="printArea"
      sx={{
        backgroundColor: "white",
        padding: 2,
        boxShadow: theme.boxShadow.primary,
        width: "80mm",
        "@media print": {
          width: "80mm",
          height: "auto",
          boxShadow: "none",
          margin: "0 0 0 0", // Pastikan margin atas 0 dan auto hanya pada margin kiri
          transformOrigin: "top ", // Pastikan skala dari sudut kiri atas
        },
      }}
    >
      <Grid container justifyItems={"start"}>
        <Grid item xs={3}>
          <img
            src="/assets/icons/logo-ajeg-hitam-aplikasi.webp"
            alt="Logo"
            style={{ width: "50px", height: "auto" }}
          />
        </Grid>
        <Grid item xs={8} textAlign={"left"}>
          <Typography variant="subtitle1">Ajeg Furniture</Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            Jl. Raya Jogja - Solo KM 10
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            Telp. 087739070444
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />

      <Typography variant="h6" sx={{ textAlign: "center", fontSize: 16 }}>
        Struk Pesanan
      </Typography>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            No. Pesanan
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Tanggal
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Sales
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">: {orderDetails.orderNumber}</Typography>
          <Typography variant="body2">
            : {formatDate(orderDetails.tanggal_transaksi)}
          </Typography>
          <Typography variant="body2">: {userName}</Typography>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />
      <Box>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Nama Pelanggan
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Nomor HP
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              : {orderDetails.nama_pelanggan}
            </Typography>
            <Typography variant="body2">
              : {maskPhoneNumber(orderDetails.nomor_hp)}
            </Typography>
          </Grid>
        </Grid>
        {/* Tambahkan detail lain dari orderDetails */}
      </Box>
      <Box sx={{ my: 2 }}>
        <Typography variant="body1">Produk:</Typography>
        {orderDetails.details.map((item, index) => (
          <Box key={index} sx={{ my: 1 }}>
            <Typography variant="body2" sx={{ fontWeight: "600" }}>
              {item.product_name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              textAlign={"right"}
            >
              {formatRupiah(item.harga_satuan)} x {item.jumlah}
            </Typography>
            {renderSpecifications(item)}
            {renderCustomizations(item)}
            {item.catatan && (
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Catatan :
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    textAlign={"right"}
                  >
                    {item.catatan}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid container sx={{ borderTop: "1px solid", my: 1 }}>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  textAlign={"right"}
                >
                  {formatRupiah(item.subtotal)}
                </Typography>
              </Grid>
            </Grid>
            {index < orderDetails.details.length - 1 && (
              <Divider sx={{ my: 2 }} />
            )}
          </Box>
        ))}
      </Box>
      {/* Produk dan total harga rendering */}
      <Box sx={{ my: 2 }}>
        <Grid container>
          <Grid item xs={7}>
            <Typography variant="body2" color="textSecondary">
              Sub Total
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Ongkir
            </Typography>
            {orderDetails.discountValue > 0 && (
              <Typography variant="body2" color="textSecondary">
                Diskon
              </Typography>
            )}
            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
              Total
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Jml Pembayaran
            </Typography>
            {calculateRemainingBalance() !== 0 && (
              <Typography variant="body2" color="textSecondary">
                Sisa
              </Typography>
            )}
          </Grid>
          <Grid item xs={5} textAlign={"right"}>
            <Typography variant="body2" color="textSecondary">
              {formatRupiah(calculateSubtotal())}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {formatRupiah(orderDetails.ongkir)}
            </Typography>
            {orderDetails.discountValue > 0 && (
              <Typography variant="body2" color="textSecondary">
                -{formatRupiah(orderDetails.discount_value)}
              </Typography>
            )}
            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
              {formatRupiah(orderDetails.total_harga)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {formatRupiah(orderDetails.jumlah_pembayaran)}
            </Typography>
            {calculateRemainingBalance() !== 0 && (
              <Typography variant="body2" color="textSecondary">
                {formatRupiah(calculateRemainingBalance())}
              </Typography>
            )}
          </Grid>
          {orderDetails.catatan && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Catatan :{" "}
              </Typography>
              <Typography variant="body2"> {orderDetails.catatan}</Typography>
            </Grid>
          )}
          {orderDetails.total_lunas && orderDetails.total_dp && (
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Detail Pembayaran
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" color="textSecondary">
                  Jml DP
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Tgl DP
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Jml Pelunasan
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Tgl Pelunasan
                </Typography>
              </Grid>
              <Grid item xs={5} textAlign={"right"}>
                <Typography variant="body2">
                  {formatRupiah(orderDetails.total_dp || 0)}
                </Typography>
                <Typography variant="body2">
                  {" "}
                  {orderDetails.tanggal_pembayaran_dp
                    ? formatDate(orderDetails.tanggal_pembayaran_dp)
                    : "-"}
                </Typography>
                <Typography variant="body2">
                  {formatRupiah(orderDetails.total_lunas || 0)}
                </Typography>
                <Typography variant="body2">
                  {" "}
                  {orderDetails.tanggal_pembayaran_lunas
                    ? formatDate(orderDetails.tanggal_pembayaran_lunas)
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Divider sx={{ my: 1 }} />
        <Typography textAlign={"center"}>Terimakasih</Typography>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 1,
          }}
        >
          <Instagram sx={{ fontSize: 16, mr: 0.5 }} />
          <Typography variant="body2">@ajeg.co.id</Typography>
          <Language sx={{ fontSize: 16, ml: 2, mr: 0.5 }} />
          <Typography variant="body2">ajeg.co.id</Typography>
        </Grid>
        <Typography variant="body2" textAlign={"center"} sx={{ fontSize: 14 }}>
          Pengaduan pelanggan : pelanggan@ajeg.co.id / 087739070444
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2" sx={{ fontSize: 13 }}>
            {`Syarat dan ketentuan penukaran barang: `}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`1. Produk yang sudah dibeli dapat ditukar maksimal dalam waktu 24 jam setelah pembelian.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`2. Penukaran produk hanya berlaku untuk semua produk sofa dan furniture kayu dengan harga sama atau lebih tinggi. Pembeli perlu membayar selisih harga jika produk yang dipilih lebih mahal.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`3. Penukaran produk hanya berlaku untuk produk yang masih dalam kondisi baik.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`4. Produk custom tidak dapat ditukar.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`5. Biaya pengiriman untuk penukaran produk ditanggung sepenuhnya oleh pembeli.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12 }}>
            {`6. Penukaran produk hanya dapat dilakukan dengan menunjukkan bukti pembelian.`}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 12, textAlign: "right" }}>
            {`Tanggal cetak: ${formatDate(new Date())}`}
          </Typography>
        </Box>
      </Box>
      <style>{`
        @media print {
          body * {
            visibility: hidden;
          }
          .printable-content, .printable-content * {
            visibility: visible;
          }
          .printable-content {
            position: absolute;
            left: 0;
            top: 0;
            width: 58mm;
          }
        }
      `}</style>
    </Box>
  );
};

export default PrintThermal;
