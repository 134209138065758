// path: src/features/orders/SettlementPage.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { Print, Receipt } from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderDetails, settlePayment } from "./ordersSlice";
import theme from "../../theme";
import { format } from "date-fns";
import formatRupiah from "../../utils/formatRupiah";

const SettlementPage = () => {
  const { transactionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transactionDetails = useSelector((state) => state.orders.orderDetails);

  const [sisaPembayaran, setSisaPembayaran] = useState(0);
  const [error, setError] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [metodePembayaran, setMetodePembayaran] = useState("");
  const handleMetodePembayaranChange = (method) => {
    setMetodePembayaran(method);
  };

  const [jumlahPembayaran, setJumlahPembayaran] = useState(0);
  const [displayJumlahPembayaran, setDisplayJumlahPembayaran] = useState("");

  const handleChangeJumlahPembayaran = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Hanya angka
    setJumlahPembayaran(parseFloat(value));
    setDisplayJumlahPembayaran(formatRupiah(value));
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    dispatch(fetchOrderDetails(transactionId));
  }, [dispatch, transactionId]);

  useEffect(() => {
    if (transactionDetails) {
      console.log("Transaction Details:", transactionDetails);
      setSisaPembayaran(transactionDetails.sisa_pembayaran);
    }
  }, [transactionDetails]);

  const handleBack = () => {
    window.history.back();
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "Invalid date";
    }
    try {
      const date = new Date(dateString);
      if (isNaN(date)) {
        throw new Error("Invalid date");
      }
      return format(date, "dd-MM-yyyy");
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid date";
    }
  };

  const calculateRemainingBalance = () => {
    return (
      transactionDetails?.total_harga - transactionDetails?.jumlah_pembayaran
    );
  };

  const handlePembayaran = () => {
    if (
      !metodePembayaran ||
      jumlahPembayaran <= 0 ||
      jumlahPembayaran > sisaPembayaran
    ) {
      setSnackbarMessage(
        "Metode pembayaran atau jumlah pembayaran tidak valid."
      );
      setOpenSnackbar(true);
      return;
    }

    console.log("Sending payment request with:", {
      transactionId,
      jumlahPembayaran,
      metodePembayaran,
    });

    dispatch(
      settlePayment({
        transactionId,
        jumlahPembayaran,
        metodePembayaran,
      })
    )
      .unwrap()
      .then(() => {
        setSnackbarMessage("Pembayaran berhasil dilakukan.");
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate("/orders/order-print", {
            state: {
              transactionId: transactionId,
            },
          }); // Pastikan untuk mengganti "Nama User" dengan variabel yang tepat jika diperlukan
        }, 2000); // Delay untuk menampilkan snackbar sebelum pindah halaman
      })
      .catch((err) => {
        setSnackbarMessage("Terjadi kesalahan saat melakukan pembayaran.");
        setOpenSnackbar(true);
      });
  };

  return (
    <Box sx={{ p: isMobile ? 2 : 3 }}>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          color: theme.palette.primary.main,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{ marginRight: 2, borderRadius: "50%", border: "1px solid" }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, fontSize: isMobile ? "1rem" : "1.2rem" }}
          >
            Buat Pelunasan
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ px: 2, mt: 10, mb: 5 }}>
        <Typography variant="subtitle1">Informasi Transaksi</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2">No Invoice</Typography>
                <Typography variant="body2">Tanggal Transaksi</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  : {transactionDetails?.order_number}
                </Typography>
                <Typography variant="body2">
                  : {formatDate(transactionDetails?.tanggal_transaksi)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2">Nama Pelanggan</Typography>
                <Typography variant="body2">Status Pembayaran</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  : {transactionDetails?.nama_pelanggan}
                </Typography>
                <Typography variant="body2">
                  : {transactionDetails?.status_pembayaran}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          p: 2,
          mb: 3,
          borderRadius: 3,
          boxShadow: theme.boxShadow.primary,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Rincian Pesanan</Typography>

            {transactionDetails?.details?.map((item, index) => (
              <Box key={index} mb={2}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body2">Nama Produk</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      : {item.product_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Harga Satuan</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      : {formatRupiah(item.harga_satuan)} x {item.jumlah}
                    </Typography>
                  </Grid>
                  {item.custom_details &&
                    item.custom_details.map((custom, idx) => (
                      <React.Fragment key={idx}>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            Custom Charges
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            : {formatRupiah(custom.custom_charges)}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Subtotal
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      : {formatRupiah(item.subtotal)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Ringkasan Tagihan</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body2">Total Tagihan</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  : {formatRupiah(transactionDetails?.total_harga)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Jumlah Pembayaran</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">
                  : {formatRupiah(transactionDetails?.jumlah_pembayaran)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Sisa Pembayaran</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  : {formatRupiah(calculateRemainingBalance())}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          p: 2,
          mb: 3,
          borderRadius: 3,
          boxShadow: theme.boxShadow.primary,
        }}
      >
        {" "}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Metode Pembayaran</Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Button
                    variant={
                      metodePembayaran === "Tunai" ? "contained" : "outlined"
                    }
                    fullWidth
                    onClick={() => handleMetodePembayaranChange("Tunai")}
                  >
                    Tunai
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant={
                      metodePembayaran === "Transfer" ? "contained" : "outlined"
                    }
                    fullWidth
                    onClick={() => handleMetodePembayaranChange("Transfer")}
                  >
                    Transfer
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant={
                      metodePembayaran === "Qris" ? "contained" : "outlined"
                    }
                    fullWidth
                    onClick={() => handleMetodePembayaranChange("Qris")}
                  >
                    Qris
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant={
                      metodePembayaran === "Debit" ? "contained" : "outlined"
                    }
                    fullWidth
                    onClick={() => handleMetodePembayaranChange("Debit")}
                  >
                    Debit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Box>
        <TextField
          label="Jumlah Pembayaran"
          inputMode="numeric"
          fullWidth
          value={displayJumlahPembayaran}
          onChange={handleChangeJumlahPembayaran}
          sx={{
            mb: 2,
          }}
          helperText="Pastikan nominal sudah benar"
        />
        <Button variant="contained" color="primary" onClick={handlePembayaran}>
          Proses Pembayaran
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(-1)}
          sx={{ ml: 2 }}
        >
          Batal
        </Button>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {snackbarMessage === "Pembayaran berhasil dilakukan." ? (
          <Box
            sx={{
              backgroundColor: "green",
              color: "white",
              p: 2,
              borderRadius: 1,
            }}
          >
            {snackbarMessage}
          </Box>
        ) : (
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        )}
      </Snackbar>
    </Box>
  );
};

export default SettlementPage;
