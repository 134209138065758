// path: src/features/dashboard/dashboardRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import SalesSummary from "./SalesSummary";
import SalesDetails from "./SalesDetails";

const dashboardRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SalesSummary />} />
      <Route path="/sales-details" element={<SalesDetails />} />
    </Routes>
  );
};

export default dashboardRoutes;
