import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const KomplimenForm = ({
  product,
  onAdditionalChange,
  onDataChange,
  validationErrors = {},
}) => {
  const [kainOptions, setKainOptions] = useState([]);
  const [kakiOptions, setKakiOptions] = useState([]);
  const [warnaOptions, setWarnaOptions] = useState([]);
  const [finishingOptions, setFinishingOptions] = useState([]);
  const [selectedWarnaId, setSelectedWarnaId] = useState("");
  const [selectedFinishingId, setSelectedFinishingId] = useState("");
  const [additionalOptions, setAdditionalOptions] = useState([
    { jenis: "", nilai: "", harga: "" },
  ]);
  const [selectedWarna, setSelectedWarna] = useState("");
  const [selectedFinishing, setSelectedFinishing] = useState("");

  const totalAdditional = additionalOptions.reduce((acc, option) => {
    const harga = parseFloat(option.harga) || 0;
    return acc + harga;
  }, 0);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const kainResponse = await fetch(
          "https://kasir.ajegjogja.com/api/options/kain"
        );
        const kakiResponse = await fetch(
          "https://kasir.ajegjogja.com/api/options/kaki"
        );

        if (!kainResponse.ok || !kakiResponse.ok) {
          throw new Error("Error fetching options");
        }

        const kainData = await kainResponse.json();
        const kakiData = await kakiResponse.json();

        setKainOptions(kainData);
        setKakiOptions(kakiData);
      } catch (error) {
        console.error("Fetching options failed:", error.message);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchFinishingOptions = async () => {
      try {
        const response = await fetch(
          "https://kasir.ajegjogja.com/api/options/finishing"
        );
        if (!response.ok) {
          throw new Error("Error fetching finishing options");
        }
        const data = await response.json();
        setFinishingOptions(data);
      } catch (error) {
        console.error("Fetching finishing options failed:", error.message);
      }
    };

    fetchFinishingOptions();
  }, []);

  const fetchWarnaOptions = async (idKain) => {
    try {
      const response = await fetch(
        `https://kasir.ajegjogja.com/api/options/warna/${idKain}`
      );
      if (!response.ok) {
        throw new Error("Error fetching warna options");
      }
      const data = await response.json();
      setWarnaOptions(data);
    } catch (error) {
      console.error("Fetching warna options failed:", error.message);
    }
  };

  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const handleAdditionalChange = (index, field, value) => {
    const updatedOptions = [...additionalOptions];
    updatedOptions[index][field] = value;
    setAdditionalOptions(updatedOptions);
    updateTotalAdditional(updatedOptions);

    if (field === "jenis" && value === "Kain") {
      fetchWarnaOptions(updatedOptions[index].nilai);
    }

    onDataChange({
      additionalOptions: updatedOptions,
      warna: selectedWarna,
      id_warna: selectedWarnaId,
      finishing: selectedFinishing,
      id_finishing: selectedFinishingId,
    });
  };

  const handleWarnaChange = (value, id) => {
    setSelectedWarna(value);
    setSelectedWarnaId(id);
    onDataChange({
      additionalOptions,
      warna: value,
      id_warna: id,
      finishing: selectedFinishing,
      id_finishing: selectedFinishingId,
    });
  };

  const handleFinishingChange = (value, id) => {
    setSelectedFinishing(value);
    setSelectedFinishingId(id);
    onDataChange({
      additionalOptions,
      warna: selectedWarna,
      id_warna: selectedWarnaId,
      finishing: value,
      id_finishing: id,
    });
  };

  const updateTotalAdditional = (options) => {
    const totalAdditional = options.reduce((acc, option) => {
      const harga = parseFloat(option.harga) || 0;
      return acc + harga;
    }, 0);
    onAdditionalChange(totalAdditional);
  };

  const handleHargaChange = (index, value) => {
    const formattedValue = value.replace(/[^\d]/g, "");
    const numericValue = formattedValue ? parseFloat(formattedValue) : 0;
    handleAdditionalChange(index, "harga", numericValue);
  };

  const addAdditionalOption = () => {
    setAdditionalOptions([
      ...additionalOptions,
      { jenis: "", nilai: "", harga: "" },
    ]);
  };

  const removeAdditionalOption = (index) => {
    const updatedOptions = additionalOptions.filter((_, i) => i !== index);
    setAdditionalOptions(updatedOptions);
    updateTotalAdditional(updatedOptions);
  };

  const clearAllAdditionalOptions = () => {
    setAdditionalOptions([{ jenis: "", nilai: "", harga: "" }]);
    updateTotalAdditional([]);
  };

  const isFinishingVisible = () => {
    const legOptionId =
      additionalOptions.find((option) => option.jenis === "Kaki")?.nilai ||
      null;
    const legNameFromOptions = legOptionId
      ? kakiOptions.find((option) => option.id_kaki === legOptionId)?.jenis_kaki
      : null;
    const legName = legNameFromOptions || product.kaki;

    const finishingVisibleLegs = ["Balok Kayu", "Skandinavian"];
    return finishingVisibleLegs.includes(legName);
  };

  const selectedKainId =
    additionalOptions.find((option) => option.jenis === "Kain")?.nilai ||
    product.id_kain;

  useEffect(() => {
    if (selectedKainId) {
      fetchWarnaOptions(selectedKainId);
    }
  }, [selectedKainId]);

  return (
    <Box sx={{ maxHeight: 350, overflowY: "scroll" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body2">Dimensi</Typography>
          <Typography variant="body2">Kain</Typography>
          {product.kaki && <Typography variant="body2">Kaki</Typography>}
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            : {product.panjang} x {product.lebar} x {product.tinggi} cm
          </Typography>
          <Typography variant="body2">: {product.kain}</Typography>
          {product.kaki && (
            <Typography variant="body2">: {product.kaki}</Typography>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1} mt={1}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
            Additional Charges / Custom:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
            {formatRupiah(totalAdditional)}
          </Typography>
        </Grid>
      </Grid>

      <Box
        component="ul"
        p={1}
        m={0}
        sx={{ border: "1px solid #ccc", borderRadius: 2, mt: 1 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
              Jenis
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
              Nilai
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontSize: "14px", mt: 1 }}>
              Harga
            </Typography>
          </Grid>

          {additionalOptions.map((option, index) => (
            <React.Fragment key={index}>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  size="small"
                  error={
                    !!validationErrors[`additionalOptions[${index}].jenis`]
                  }
                >
                  <InputLabel id={`jenis-label-${index}`}>
                    Pilih Jenis
                  </InputLabel>
                  <Select
                    labelId={`jenis-label-${index}`}
                    value={option.jenis}
                    label="Pilih Jenis"
                    onChange={(event) =>
                      handleAdditionalChange(index, "jenis", event.target.value)
                    }
                  >
                    <MenuItem value="Dimensi">Dimensi</MenuItem>
                    <MenuItem value="Kain">Kain</MenuItem>
                    {product.kaki && <MenuItem value="Kaki">Kaki</MenuItem>}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                {option.jenis === "Dimensi" ? (
                  <TextField
                    fullWidth
                    size="small"
                    value={option.nilai}
                    onChange={(event) =>
                      handleAdditionalChange(index, "nilai", event.target.value)
                    }
                    placeholder="Masukkan nilai"
                  />
                ) : option.jenis === "Kaki" ? (
                  <FormControl fullWidth size="small">
                    <InputLabel id={`nilai-label-${index}`}>
                      Pilih Nilai
                    </InputLabel>
                    <Select
                      labelId={`nilai-label-${index}`}
                      value={option.nilai}
                      label="Pilih Nilai"
                      onChange={(event) =>
                        handleAdditionalChange(
                          index,
                          "nilai",
                          event.target.value
                        )
                      }
                    >
                      {kakiOptions.map((opt) => (
                        <MenuItem key={opt.id_kaki} value={opt.id_kaki}>
                          {opt.jenis_kaki}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth size="small">
                    <InputLabel id={`nilai-label-${index}`}>
                      Pilih Nilai
                    </InputLabel>
                    <Select
                      labelId={`nilai-label-${index}`}
                      value={option.nilai}
                      label="Pilih Nilai"
                      onChange={(event) =>
                        handleAdditionalChange(
                          index,
                          "nilai",
                          event.target.value
                        )
                      }
                    >
                      {kainOptions.map((opt) => (
                        <MenuItem key={opt.id_kain} value={opt.id_kain}>
                          {opt.kain}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={4} display="flex">
                <TextField
                  fullWidth
                  size="small"
                  value={option.harga}
                  onChange={(event) =>
                    handleHargaChange(index, event.target.value)
                  }
                  placeholder="Masukkan harga"
                />
                <IconButton
                  onClick={() => removeAdditionalOption(index)}
                  color="secondary"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              onClick={addAdditionalOption}
              variant="contained"
              fullWidth
              sx={{ mt: 1 }}
            >
              Tambah Pilihan
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={clearAllAdditionalOptions}
              variant="outlined"
              fullWidth
              sx={{ mt: 1 }}
            >
              Hapus Semua
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {(additionalOptions.some((option) => option.jenis === "Kain") ||
            product.kain) && (
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                error={!!validationErrors.warna}
              >
                <InputLabel id="pilihan-warna">Pilih Warna</InputLabel>
                <Select
                  labelId="pilihan-warna"
                  value={selectedWarna}
                  label="Pilih Warna"
                  onChange={(e) => {
                    const selectedOption = warnaOptions.find(
                      (option) => option.warna === e.target.value
                    );
                    handleWarnaChange(e.target.value, selectedOption.id_warna);
                  }}
                >
                  {warnaOptions.map((warna) => (
                    <MenuItem key={warna.id_warna} value={warna.warna}>
                      {warna.warna}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {isFinishingVisible() && (
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                error={!!validationErrors.finishing}
              >
                <InputLabel id="pilihan-finishing">Finishing</InputLabel>
                <Select
                  labelId="pilihan-finishing"
                  value={selectedFinishing}
                  label="Finishing"
                  onChange={(e) => {
                    const selectedOption = finishingOptions.find(
                      (option) => option.finishing === e.target.value
                    );
                    handleFinishingChange(
                      e.target.value,
                      selectedOption.id_finishing
                    );
                  }}
                >
                  {finishingOptions.map((finishing) => (
                    <MenuItem
                      key={finishing.id_finishing}
                      value={finishing.finishing}
                    >
                      {finishing.finishing}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default KomplimenForm;
