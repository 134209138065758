// path: src/features/Sales/PrintReceipt.js

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { fetchOrders } from "./ordersSlice";
import OrderList from "./components/OrderList";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";

const OrderPage = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders);
  const orderStatus = useSelector((state) => state.orders.status);
  const error = useSelector((state) => state.orders.error);
  const navigate = useNavigate();
  const isMobile = window.innerWidth < theme.breakpoints.values.sm;

  useEffect(() => {
    dispatch(fetchOrders());
    console.log("OrderPage - orders:", orders);
  }, [dispatch]);

  const handleBack = () => {
    navigate("/");
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          color: theme.palette.primary.main,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{ marginRight: 2, borderRadius: "50%", border: "1px solid" }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ fontSize: isMobile ? "1rem" : "1.2rem" }}
          >
            Daftar Pesanan
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ m: 2, mt: 10 }}>
        <OrderList orders={orders} />
      </Box>
    </Box>
  );
};

export default OrderPage;
