// features/Sales/OrderDetails.js
import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
  IconButton,
  Grid,
  Avatar,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useSelector, useDispatch } from "react-redux";
import { removeItem } from "./slices/orderSlice";

const OrderItemsSummary = () => {
  const dispatch = useDispatch();
  const orderItems = useSelector((state) => state.order.items);

  const handleRemoveItem = (index) => {
    dispatch(removeItem(index));
  };

  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const renderCustomizations = (item) => {
    const { additionalOptions, warna, finishing } = item.formData;

    if (additionalOptions && additionalOptions.length > 0) {
      const customTexts = additionalOptions
        .filter((option) => option.nilai)
        .map((option) => option.jenis)
        .join(" - ");
      return (
        <Typography variant="body2" color="textSecondary">
          Custom: {customTexts} {warna ? `, ${warna}` : ""}{" "}
          {finishing ? `, ${finishing}` : ""}
        </Typography>
      );
    } else {
      return (
        <Typography variant="body2" color="textSecondary">
          Standard: {warna ? `${warna},` : ""}{" "}
          {finishing ? ` ${finishing}` : ""}
        </Typography>
      );
    }
  };

  return (
    <Box sx={{ flex: "1 1 auto", overflowY: "scroll" }}>
      <List>
        {orderItems.map((item, index) => (
          <ListItem key={index} sx={{ alignItems: "flex-start" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Avatar
                  variant="square"
                  src={item.foto_produk}
                  alt={item.nama}
                  sx={{ width: 60, height: 60, borderRadius: 2 }}
                />
              </Grid>
              <Grid item xs>
                <ListItemText
                  primary={
                    <Typography variant="body2" sx={{ fontWeight: "600" }}>
                      {item.nama}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="textSecondary">
                        {formatRupiah(item.harga_jual)} x {item.quantity}
                      </Typography>
                      {item.totalAdditional > 0 && (
                        <Typography variant="body2" color="textSecondary">
                          + {formatRupiah(item.totalAdditional)}
                        </Typography>
                      )}
                      {renderCustomizations(item)}
                    </>
                  }
                />
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => handleRemoveItem(index)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default OrderItemsSummary;
