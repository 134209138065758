import React, { useState, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableFooter,
  useMediaQuery,
  Tab,
} from "@mui/material";
import { format } from "date-fns";
import formatRupiah from "../../utils/formatRupiah";

const SettlementDetails = ({ settlementData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd-MM-yyyy");
  };

  const paginatedData = settlementData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Calculate total pelunasan
  const totalPelunasan = useMemo(() => {
    return settlementData.reduce(
      (sum, row) => sum + parseInt(row.pelunasan, 10),
      0
    );
  }, [settlementData]);

  return (
    <Box
      sx={{
        width: isMobile ? "320px" : "1200px",
        overflow: "auto",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Detail Pelunasan
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nama Sales</TableCell>
              <TableCell>Tgl Transaksi</TableCell>
              <TableCell>Nama Pelanggan</TableCell>
              <TableCell>Produk</TableCell>
              <TableCell>Total Tagihan</TableCell>
              <TableCell>DP</TableCell>
              <TableCell>Pelunasan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.nama_sales}</TableCell>
                <TableCell>{formatDate(row.tanggal_transaksi)}</TableCell>
                <TableCell>{row.nama_pelanggan}</TableCell>
                <TableCell>{row.produk}</TableCell>
                <TableCell>{formatRupiah(row.total_tagihan)}</TableCell>
                <TableCell>{formatRupiah(row.dp)}</TableCell>
                <TableCell>{formatRupiah(row.pelunasan)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                colSpan={5}
                sx={{ fontSize: "14px", fontWeight: 600, color: "black" }}
              >
                Total Pelunasan
              </TableCell>
              <TableCell
                sx={{ fontSize: "14px", fontWeight: 600, color: "black" }}
              >
                {formatRupiah(totalPelunasan)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={settlementData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}></Box>
    </Box>
  );
};

export default SettlementDetails;
