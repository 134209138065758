// path: src/features/products/AddProduct.js

import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  InputAdornment,
  IconButton,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMetadata,
  addProduct,
  fetchExistingSKUs,
  setSubcategories,
  fetchProductTypes,
  fetchSubcategories,
  fetchVendorDetails,
} from "./productSlice";
import KinamiForm from "./components/KinamiForm";
import ImageUploadCard from "./components/ImageUploadCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const AddProduct = () => {
  const dispatch = useDispatch();
  const { categories, vendors, subcategories, skus, productTypes } =
    useSelector((state) => state.products.metadata);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [product, setProduct] = useState({
    name: "",
    category: "",
    subCategory: "",
    vendor: "",
    productType: "",
    sku: "",
    productionTime: "",
    warrantyPeriod: "",
    length: "",
    width: "",
    height: "",
    description: "",
    salePrice: "",
    purchasePrice: "",
    files: [],
    kinamiSpecs: {}, // Initialize kinamiSpecs object
  });

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(fetchMetadata());
    dispatch(fetchExistingSKUs());
    dispatch(fetchProductTypes()); // Tambahkan ini untuk mengambil product types
  }, [dispatch]);

  useEffect(() => {
    if (categories.length > 0) {
      console.log("Kategori yang diambil:", categories);
    }
  }, [categories]);

  useEffect(() => {
    if (productTypes.length > 0) {
      console.log("Jenis produk yang diambil:", productTypes);
    }
  }, [productTypes]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "category") {
      dispatch(fetchSubcategories(value));
    }

    if (name === "vendor") {
      dispatch(fetchVendorDetails(value));
    }

    console.log(`Field changed: ${name}, Value: ${value}`); // Log field and value

    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (
      product.name &&
      product.category &&
      product.subCategory &&
      product.vendor
    ) {
      generateSKU();
    }
  }, [product.name, product.category, product.subCategory, product.vendor]);

  const generateSKU = () => {
    const vendorCode = product.vendor
      ? vendors
          .find((vendor) => vendor.id_vendor === product.vendor)
          .nama_vendor.slice(0, 3)
          .toUpperCase()
      : "XXX";

    const categoryCode = product.category
      ? categories
          .find((category) => category.id_kategori === product.category)
          .kategori.slice(0, 3)
          .toUpperCase()
      : "XXX";

    const subcategoryCode = product.subCategory
      ? subcategories
          .find(
            (subcategory) => subcategory.id_subkategori === product.subCategory
          )
          ?.subkategori?.split(" ")
          .map((word) => word.slice(0, 3).toUpperCase())
          .join("") || "XXX"
      : "XXX";

    const productNameCode = product.name
      ? product.name
          .split(" ")
          .map((word) => word.slice(0, 3).toUpperCase())
          .join("")
          .slice(0, 6)
      : "XXX";

    let skuBase = `${vendorCode}-${categoryCode}-${subcategoryCode}-${productNameCode}`;

    const existingSKUs = skus.map((sku) => sku.split("-")[0]);
    console.log("Existing SKUs:", existingSKUs);

    let highestExistingNumber = Math.max(
      ...existingSKUs.map((numStr) => parseInt(numStr, 10))
    );
    let uniqueNumber = highestExistingNumber + 1;

    // Loop untuk mencari nomor unik yang belum digunakan
    while (existingSKUs.includes(String(uniqueNumber).padStart(3, "0"))) {
      uniqueNumber++;
    }

    const sku = `${String(uniqueNumber).padStart(3, "0")}-${skuBase}`;

    // Update nomor unik untuk penggunaan berikutnya
    setProduct((prevProduct) => ({
      ...prevProduct,
      sku: sku,
    }));

    // Tingkatkan uniqueNumber untuk penggunaan berikutnya
    uniqueNumber++;
  };

  const getCategoryNameById = (id) => {
    const category = categories.find((category) => category.id_kategori === id);
    return category ? category.kategori.toLowerCase() : "";
  };

  const handleDescriptionChange = (event) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      description: event.target.value,
    }));
  };

  const handleBack = () => {
    window.history.back();
  };

  const isFormValid = () => {
    if (
      !product.files ||
      !product.name ||
      !product.category ||
      !product.subCategory ||
      !product.vendor ||
      !product.productType ||
      !product.productionTime ||
      !product.warrantyPeriod ||
      !product.length ||
      !product.width ||
      !product.height ||
      !product.description ||
      !product.salePrice ||
      !product.purchasePrice
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      handleOpenModal(
        "Harap isi semua field yang diperlukan sebelum menyimpan produk."
      );
      return;
    }

    const formData = new FormData();
    formData.append("nama", capitalizeEachWord(product.name)); // Kapitalisasi nama produk
    formData.append("id_kategori", product.category);
    formData.append("id_subkategori", product.subCategory);
    formData.append("id_vendor", product.vendor);
    formData.append("sku", product.sku);
    formData.append("jenis_produk", product.productType);
    formData.append("estimasi_waktu_produksi", product.productionTime);
    formData.append("masa_garansi", product.warrantyPeriod);
    formData.append("panjang", product.length);
    formData.append("lebar", product.width);
    formData.append("tinggi", product.height);
    formData.append("deskripsi", product.description);
    formData.append("harga_jual", product.salePrice);
    formData.append("harga_beli", product.purchasePrice);

    formData.append("kinamiSpecs", JSON.stringify(product.kinamiSpecs || {}));

    if (product.files && product.files.length > 0) {
      formData.append("foto_produk", product.files[0]);
    }

    try {
      await dispatch(addProduct(formData)).unwrap();
      handleOpenModal("Produk berhasil disimpan.");
    } catch (error) {
      handleOpenModal("Error saat menyimpan produk: " + error.message);
    }
  };

  // Fungsi untuk kapitalisasi setiap kata di nama produk
  const capitalizeEachWord = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handleOpenModal = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const navigateToAddProduct = () => {
    window.location.reload();
  };

  const navigateToProductsPage = () => {
    window.history.back();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          color: theme.palette.primary.main,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{ marginRight: 2, borderRadius: "50%", border: "1px solid" }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black", fontSize: isMobile ? 18 : 24 }}
          >
            Tambah Produk
          </Typography>
          <Button
            variant="contained"
            sx={{
              fontSize: isMobile ? 12 : 16,
              "&:hover": {
                "& svg": {
                  transform: "rotate(-45deg)",
                },
              },
              textTransform: "none",
            }}
            startIcon={<SendIcon sx={{ transition: "transform 0.3s" }} />}
            onClick={handleSubmit}
          >
            Simpan Produk
          </Button>
        </Toolbar>
      </AppBar>

      <Grid
        container
        spacing={3}
        sx={{ paddingLeft: "20px", paddingRight: "20px", mt: 8 }}
      >
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, marginBottom: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Foto Produk
            </Typography>
            <ImageUploadCard product={product} setProduct={setProduct} />
          </Paper>
          {typeof product.category === "number" &&
            (getCategoryNameById(product.category) === "sofa" ||
              getCategoryNameById(product.category) === "komplimen") && (
              <Paper sx={{ padding: 2, marginBottom: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Spesifikasi Khusus
                </Typography>
                <KinamiForm product={product} setProduct={setProduct} />
              </Paper>
            )}
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Informasi Produk
            </Typography>
            <TextField
              fullWidth
              label="Nama Produk"
              size="small"
              name="name"
              value={product.name}
              onChange={handleChange}
              helperText="Harus dalam format Nama Produk Subkategori. contoh //New Shizu 2 Seater// "
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Jenis Produk</InputLabel>
                  <Select
                    name="productType"
                    value={product.productType}
                    onChange={handleChange}
                    label="Jenis Produk"
                    size="small"
                  >
                    {productTypes.map((type) => (
                      <MenuItem key={type.id_jenis} value={type.id_jenis}>
                        {type.jenis_produk}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Kategori</InputLabel>
                  <Select
                    name="category"
                    value={product.category}
                    onChange={handleChange}
                    label="Kategori"
                    size="small"
                  >
                    {categories.map((category) => (
                      <MenuItem
                        key={category.id_kategori}
                        value={category.id_kategori}
                      >
                        {category.kategori}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Subkategori</InputLabel>
                  <Select
                    name="subCategory"
                    value={product.subCategory || ""}
                    onChange={handleChange}
                    label="Subkategori"
                    size="small"
                  >
                    {subcategories.map((subCategory) => (
                      <MenuItem
                        key={subCategory.id_subkategori}
                        value={subCategory.id_subkategori}
                      >
                        {subCategory.subkategori}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
              <InputLabel>Vendor</InputLabel>
              <Select
                name="vendor"
                value={product.vendor}
                onChange={handleChange}
                label="Vendor"
                size="small"
              >
                {vendors.map((vendor) => (
                  <MenuItem key={vendor.id_vendor} value={vendor.id_vendor}>
                    {vendor.nama_vendor}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="SKU - dibuat secara otomatis"
              name="sku"
              value={product.sku}
              margin="normal"
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Estimasi Waktu Produksi"
                  name="productionTime"
                  type="number"
                  value={product.productionTime}
                  onChange={handleChange}
                  margin="normal"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Hari</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Masa Garansi (tahun)"
                  name="warrantyPeriod"
                  type="number"
                  value={product.warrantyPeriod}
                  onChange={handleChange}
                  margin="normal"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Tahun</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Typography variant="subtitle1">Dimensi Produk</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Panjang"
                  name="length"
                  type="number"
                  value={product.length}
                  onChange={handleChange}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Lebar"
                  name="width"
                  type="number"
                  value={product.width}
                  onChange={handleChange}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Tinggi"
                  name="height"
                  type="number"
                  value={product.height}
                  onChange={handleChange}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              label="Deskripsi Produk"
              multiline
              rows={4}
              value={product.description}
              onChange={handleDescriptionChange}
              variant="outlined"
              fullWidth
              margin="normal"
              size="small"
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Harga Jual"
                  name="salePrice"
                  type="number"
                  value={product.salePrice}
                  onChange={handleChange}
                  margin="normal"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />
                <Typography variant="body2" color="textSecondary">
                  {`Rp. ${Number(product.salePrice).toLocaleString("id-ID")}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Harga Beli"
                  name="purchasePrice"
                  type="number"
                  value={product.purchasePrice}
                  onChange={handleChange}
                  margin="normal"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />
                <Typography variant="body2" color="textSecondary">
                  {`Rp. ${Number(product.purchasePrice).toLocaleString(
                    "id-ID"
                  )}`}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Notifikasi
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                onClick={navigateToAddProduct}
              >
                Tambahkan Produk Baru
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={navigateToProductsPage}
              >
                Kembali
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddProduct;
