import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOrderDetails } from "./ordersSlice";
import {
  CircularProgress,
  Typography,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Button,
  TextField,
  Divider,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import theme from "../../theme";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { format } from "date-fns";
import { Edit, Print, Receipt } from "@mui/icons-material";
import { updateOrderStatus } from "./ordersSlice";
import formatRupiah from "../../utils/formatRupiah";

const statusStyles = {
  pending: { backgroundColor: "#34495e", color: "white" },
  povendor: { backgroundColor: "#9b59b6", color: "black" },
  siapdikirim: { backgroundColor: "#54a0ff", color: "white" },
  terkirim: { backgroundColor: "#30cb83", color: "white" },
  dibatalkan: { backgroundColor: "#b33771", color: "white" },
  return: { backgroundColor: "#e74c3c", color: "white" },
  default: { backgroundColor: "#d3d3d3", color: "black" },
};

const OrderDetailPage = () => {
  const { transactionId } = useParams();
  const dispatch = useDispatch();
  const orderDetails = useSelector((state) => state.orders.orderDetails);
  const status = useSelector((state) => state.orders.status);
  const error = useSelector((state) => state.orders.error);

  const [newStatus, setNewStatus] = React.useState({});
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSettlement = () => {
    navigate(`/orders/settlement/${transactionId}`);
  };

  const isLunas =
    orderDetails && orderDetails.status_pembayaran.toLowerCase() === "lunas";

  const handleStatusChange = (e, item) => {
    const updatedStatus = e.target.value;
    setNewStatus((prevState) => ({
      ...prevState,
      [item.id]: updatedStatus,
    }));
  };

  const handleStatusUpdate = (itemId) => {
    const updatedStatus = newStatus[itemId];
    if (updatedStatus) {
      dispatch(
        updateOrderStatus({
          transactionId,
          detailId: itemId,
          status: updatedStatus,
        })
      );
    }
  };

  // Pada bagian render status:
  const renderStatus = (item) => {
    const currentStatus = newStatus[item.id] || item.detail_status;
    const statusKey = currentStatus.toLowerCase().replace(" ", "");

    return (
      <Grid container spacing={1} sx={{ flexGrow: 1 }}>
        <Grid item xs={8}>
          <Box
            sx={{
              ...(statusStyles[statusKey] || statusStyles.default),
              borderRadius: "4px",
            }}
          >
            <Select
              value={currentStatus}
              onChange={(e) => handleStatusChange(e, item)}
              displayEmpty
              fullWidth
              size="small"
              sx={{
                color:
                  statusStyles[statusKey]?.color || statusStyles.default.color,
                border: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
            >
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="PO Vendor">PO Vendor</MenuItem>
              <MenuItem value="Siap Dikirim">Siap Dikirim</MenuItem>
              <MenuItem value="Terkirim">Terkirim</MenuItem>
              <MenuItem value="Dibatalkan">Dibatalkan</MenuItem>
              <MenuItem value="Return">Return</MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Button
            onClick={() => handleStatusUpdate(item.id)}
            sx={{
              backgroundColor: "#4caf50",
              color: "white",
              "&:hover": {
                backgroundColor: "#45a049",
              },
            }}
          >
            Simpan
          </Button>
        </Grid>
      </Grid>
    );
  };

  const navigate = useNavigate();

  const handleBack = () => {
    window.history.back();
  };

  const handlePrint = () => {
    navigate("/orders/order-print", {
      state: { orderDetails },
    });
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd-MM-yyyy");
  };

  console.log(`OrderDetailPage - transactionId: ${transactionId}`); // Log transactionId

  useEffect(() => {
    dispatch(fetchOrderDetails(transactionId));
    console.log(`fetchOrderDetails(${transactionId})`); // Log fetchOrderDetails
  }, [dispatch, transactionId]);

  useEffect(() => {
    if (orderDetails) {
      console.log("Order Details:", orderDetails);
    }
  }, [orderDetails]);

  if (status === "loading") {
    return <CircularProgress />;
  }

  if (status === "failed") {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (!orderDetails) {
    return <Typography color="error">No order details found</Typography>;
  }

  const renderSpecifications = (item) => {
    const sofaDetail = item.sofa_details ? item.sofa_details[0] : null; // Cek apakah sofa_details ada

    return (
      <Grid container spacing={1}>
        <Grid item xs={4}>
          {(item.panjang || item.lebar || item.tinggi) && (
            <Typography variant="body2">Dimensi</Typography>
          )}
          {sofaDetail && sofaDetail.kain && (
            <Typography variant="body2">Kain</Typography>
          )}
          {sofaDetail && sofaDetail.jenis_kaki && (
            <Typography variant="body2">Kaki</Typography>
          )}
          {sofaDetail && sofaDetail.dudukan && (
            <Typography variant="body2">Dudukan</Typography>
          )}
          {sofaDetail && sofaDetail.bantal_peluk !== null && (
            <Typography variant="body2">B Peluk</Typography>
          )}
          {sofaDetail && sofaDetail.bantal_sandaran !== null && (
            <Typography variant="body2">B Sandaran</Typography>
          )}
          {sofaDetail && sofaDetail.kantong_remot !== null && (
            <Typography variant="body2">K Remot</Typography>
          )}
          {sofaDetail && sofaDetail.puff !== null && (
            <Typography variant="body2">Puff</Typography>
          )}
        </Grid>
        <Grid item xs={8}>
          {(item.panjang || item.lebar || item.tinggi) && (
            <Typography variant="body2">
              : {item.panjang || "-"} x {item.lebar || "-"} x{" "}
              {item.tinggi || "-"} cm
            </Typography>
          )}
          {sofaDetail && sofaDetail.kain && (
            <Typography variant="body2">: {sofaDetail.kain}</Typography>
          )}
          {sofaDetail && sofaDetail.jenis_kaki && (
            <Typography variant="body2">: {sofaDetail.jenis_kaki}</Typography>
          )}
          {sofaDetail && sofaDetail.dudukan && (
            <Typography variant="body2">: {sofaDetail.dudukan}</Typography>
          )}
          {sofaDetail && sofaDetail.bantal_peluk !== null && (
            <Typography variant="body2">: {sofaDetail.bantal_peluk}</Typography>
          )}
          {sofaDetail && sofaDetail.bantal_sandaran !== null && (
            <Typography variant="body2">
              : {sofaDetail.bantal_sandaran}
            </Typography>
          )}
          {sofaDetail && sofaDetail.kantong_remot !== null && (
            <Typography variant="body2">
              : {sofaDetail.kantong_remot}
            </Typography>
          )}
          {sofaDetail && sofaDetail.puff !== null && (
            <Typography variant="body2">
              : {sofaDetail.puff ? "Ya" : "Tidak"}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderCustomizations = (item) => {
    const { custom_details, warna, finishing } = item;

    if (custom_details && custom_details.length > 0) {
      console.log("Custom Details:", custom_details); // Log custom details

      return (
        <Box>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mt: 1, fontWeight: 600 }}
          >
            Custom Details
          </Typography>
          <Grid container>
            {custom_details.map((detail, index) => (
              <React.Fragment key={index}>
                {detail.dimensi && (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Dimensi
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        : {detail.dimensi}
                      </Typography>
                    </Grid>
                  </>
                )}
                {detail.jenis_kain && (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Kain
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        : {detail.jenis_kain}
                      </Typography>
                    </Grid>
                  </>
                )}
                {detail.jenis_kaki && (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Kaki
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        : {detail.jenis_kaki}
                      </Typography>
                    </Grid>
                  </>
                )}
                {detail.jenis_dudukan && (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="textSecondary">
                        Dudukan
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        : {detail.jenis_dudukan}
                      </Typography>
                    </Grid>
                  </>
                )}
              </React.Fragment>
            ))}
            {warna && (
              <>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    Warna
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    : {warna}
                  </Typography>
                </Grid>
              </>
            )}
            {finishing && (
              <>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    Finishing
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    : {finishing}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      );
    } else {
      return (
        <Grid container>
          {warna && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">
                  Warna
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  : {warna}
                </Typography>
              </Grid>
            </>
          )}
          {finishing && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">
                  Finishing
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  : {finishing}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      );
    }
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          color: theme.palette.primary.main,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{ marginRight: 2, borderRadius: "50%", border: "1px solid" }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, fontSize: isMobile ? "1rem" : "1.2rem" }}
          >
            Detail Pesanan
          </Typography>
          <Button variant="contained" onClick={handlePrint} sx={{ mr: 2 }}>
            <Print sx={{ mr: 1 }} />
            Cetak
          </Button>{" "}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSettlement}
            disabled={isLunas} // Nonaktifkan tombol jika status pembayaran lunas
          >
            <Receipt sx={{ mr: 1 }} />
            Pelunasan
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ m: 2, mt: 10 }}>
        <Box mt={2}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Grid item container>
                <Grid item xs={6} md={4}>
                  <Typography variant="body2">Nama Pelanggan</Typography>
                  <Typography variant="body2">Nomor HP</Typography>
                  <Typography variant="body2">Sales</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    : {orderDetails.nama_pelanggan}
                  </Typography>
                  <Typography variant="body2">
                    : {orderDetails.nomor_hp}
                  </Typography>
                  <Typography variant="body2">
                    : {orderDetails.sales_name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid item container>
                <Grid item xs={6} md={4}>
                  <Typography variant="body2">Nomor Invoice</Typography>
                  <Typography variant="body2">Tanggal Pembelian</Typography>
                  <Typography variant="body2">Status Pembayaran</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    : {orderDetails.order_number}
                  </Typography>
                  <Typography variant="body2">
                    : {formatDate(orderDetails.tanggal_transaksi)}
                  </Typography>
                  <Typography variant="body2">
                    : {orderDetails.status_pembayaran}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid item container>
                <Grid item xs={6} md={4}>
                  <Typography variant="body2">Total Tagihan</Typography>
                  <Typography variant="body2">Jumlah Pembayaran</Typography>
                  <Typography variant="body2">Request Pengiriman</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    : {formatRupiah(orderDetails.total_harga)}
                  </Typography>
                  <Typography variant="body2">
                    : {formatRupiah(orderDetails.jumlah_pembayaran)}
                  </Typography>
                  <Typography variant="body2">
                    : {formatDate(orderDetails.tanggal_pengiriman)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ borderBottom: "1px solid", my: 2 }} />
        </Box>
        <Box mt={4}>
          <Typography variant="h6">Produk</Typography>
          {orderDetails.details.map((item, index) => (
            <Box
              key={index}
              mt={2}
              sx={{ borderRadius: 5, boxShadow: theme.boxShadow.primary, p: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <img
                    src={item.foto_produk}
                    alt={item.product_name}
                    style={{ width: "220px", borderRadius: "5px" }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>{item.product_name}</Typography>
                  {renderSpecifications(item)}
                </Grid>
                <Grid item xs={12} md={3}>
                  {renderCustomizations(item)}
                  <TextField
                    multiline
                    rows={3}
                    label="Catatan"
                    value={item.catatan}
                    fullWidth
                    variant="outlined"
                    disabled
                    sx={{ mt: 1 }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2">Alamat Pelanggan:</Typography>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant="body2">Provinsi</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      : {orderDetails.provinsi}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2">Kota</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      : {orderDetails.kabupaten}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2">Kecamatan</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      : {orderDetails.kecamatan}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2">Kelurahan</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      : {orderDetails.kelurahan}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2">Alamat Lengkap</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      : {orderDetails.alamat_pelanggan}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Status Pesanan</Typography>
                      {renderStatus(item)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default OrderDetailPage;
