// path: features/Customers/components/CustomerModal.js
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  Modal,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addCustomer } from "../customerSlice"; // Tambahkan aksi untuk menambah pelanggan

const CustomerModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState({
    title: "",
    nama: "",
    alamat: "",
    nomorHp: "",
    provinsi: { id: "34", name: "DI YOGYAKARTA" },
    kota: "",
    kecamatan: "",
    kelurahan: "",
  });
  const [provinsiOptions, setProvinsiOptions] = useState([]);
  const [kotaOptions, setKotaOptions] = useState([]);
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [kelurahanOptions, setKelurahanOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProvinsi = async () => {
      try {
        const response = await axios.get(
          "https://kasir.ajegjogja.com/api/alamat/provinsi"
        );
        setProvinsiOptions(response.data);
        fetchKota("34"); // Load default kota for provinsi id 34
      } catch (error) {
        console.error("Error fetching provinsi:", error);
      }
    };
    fetchProvinsi();
  }, []);

  const fetchKota = async (provinsiId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://kasir.ajegjogja.com/api/alamat/kota",
        {
          params: { provinsi_id: provinsiId },
        }
      );
      setKotaOptions(response.data);
    } catch (error) {
      console.error(
        "Error fetching kota:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKecamatan = async (kotaId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://kasir.ajegjogja.com/api/alamat/kecamatan",
        {
          params: { kota_id: kotaId },
        }
      );
      setKecamatanOptions(response.data);
    } catch (error) {
      console.error(
        "Error fetching kecamatan:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchKelurahan = async (kecamatanId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://kasir.ajegjogja.com/api/alamat/kelurahan",
        {
          params: { kecamatan_id: kecamatanId },
        }
      );
      setKelurahanOptions(response.data);
    } catch (error) {
      console.error(
        "Error fetching kelurahan:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleProvinsiSelect = async (event, value) => {
    if (value) {
      setCustomer((prevCustomer) => ({ ...prevCustomer, provinsi: value }));
      fetchKota(value.id);
    }
  };

  const handleKotaSelect = async (event, value) => {
    if (value) {
      setCustomer((prevCustomer) => ({ ...prevCustomer, kota: value.name }));
      fetchKecamatan(value.id);
    }
  };

  const handleKecamatanSelect = async (event, value) => {
    if (value) {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        kecamatan: value.name,
      }));
      fetchKelurahan(value.id);
    }
  };

  const handleKelurahanSelect = (event, value) => {
    if (value) {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        kelurahan: value.name,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomer((prevCustomer) => ({ ...prevCustomer, [name]: value }));
  };

  const handleSave = () => {
    dispatch(addCustomer(customer));
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2">
          Tambah Pelanggan Baru
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Sapaan</InputLabel>
              <Select
                value={customer.title}
                size="small"
                onChange={handleInputChange}
                label="Sapaan"
                name="title"
              >
                <MenuItem value="Bapak">Bapak</MenuItem>
                <MenuItem value="Ibu">Ibu</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Nama Lengkap"
              name="nama"
              size="small"
              value={customer.nama}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nomor HP"
              size="small"
              name="nomorHp"
              type="tel"
              value={customer.nomorHp}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={provinsiOptions}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleProvinsiSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Provinsi"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={kotaOptions}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleKotaSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kota"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={kecamatanOptions}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleKecamatanSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kecamatan"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={kelurahanOptions}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleKelurahanSelect}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kelurahan"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Alamat Lengkap"
              size="small"
              name="alamat"
              multiline
              rows={3}
              value={customer.alamat}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={handleClose} color="secondary" sx={{ mr: 2 }}>
            Batal
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Simpan
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerModal;
