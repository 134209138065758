// path: src/features/sales/OrderModal/FabrikasiForm.js
import React from "react";
import { Grid, Box, Typography } from "@mui/material";

const FabrikasiForm = ({ product }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body2">Dimensi</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            : {product.panjang} x {product.lebar} x {product.tinggi} cm
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FabrikasiForm;
