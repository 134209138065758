// path: src/features/promotions/promotionSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { verifyToken } from "../../actions/userActions";

const baseUrl = process.env.REACT_APP_BASE_URL; // Ambil baseUrl dari environment variable

// Async thunk untuk mengambil daftar promosi dari server
export const fetchPromotions = createAsyncThunk(
  "promotions/fetchPromotions",
  async (_, { getState }) => {
    try {
      await verifyToken(); // Verifikasi token sebelum melakukan permintaan

      const token = getState().auth.token; // Ambil token dari state Redux (asumsikan token tersimpan di state)

      // Permintaan ke server dengan menggunakan baseUrl dari environment variable
      const response = await axios.get(`${baseUrl}/api/promotions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching promotions:", error);
      throw error;
    }
  }
);

// Async thunk untuk menambahkan promosi baru
export const addPromotion = createAsyncThunk(
  "promotions/addPromotion",
  async (promotionData, { getState }) => {
    try {
      await verifyToken(); // Verifikasi token sebelum melakukan permintaan

      const token = getState().auth.token; // Ambil token dari state Redux

      // Permintaan ke server untuk menambahkan promosi baru
      const response = await axios.post(
        `${baseUrl}/api/promotions`,
        promotionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error adding promotion:", error);
      throw error;
    }
  }
);

// Async thunk untuk menghapus promosi berdasarkan ID
export const deletePromotion = createAsyncThunk(
  "promotions/deletePromotion",
  async (promotionId, { getState }) => {
    try {
      await verifyToken(); // Verifikasi token sebelum melakukan permintaan

      const token = getState().auth.token; // Ambil token dari state Redux

      // Permintaan ke server untuk menghapus promosi berdasarkan ID
      await axios.delete(`${baseUrl}/api/promotions/${promotionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return promotionId; // Mengembalikan ID promosi yang dihapus
    } catch (error) {
      console.error("Error deleting promotion:", error);
      throw error;
    }
  }
);

const promotionSlice = createSlice({
  name: "promotions",
  initialState: {
    promotions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromotions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPromotions.fulfilled, (state, action) => {
        state.loading = false;
        state.promotions = action.payload;
      })
      .addCase(fetchPromotions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Simpan pesan error
      })
      .addCase(addPromotion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPromotion.fulfilled, (state, action) => {
        state.loading = false;
        state.promotions.push(action.payload); // Tambahkan promosi baru ke array
      })
      .addCase(addPromotion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Simpan pesan error
      })
      .addCase(deletePromotion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePromotion.fulfilled, (state, action) => {
        state.loading = false;
        state.promotions = state.promotions.filter(
          (promotion) => promotion.id !== action.payload
        ); // Hapus promosi dari array berdasarkan ID
      })
      .addCase(deletePromotion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Simpan pesan error
      });
  },
});

export default promotionSlice.reducer;
