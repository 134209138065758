// path: src/features/Sales/slices/customerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  title: "",
  nama: "",
  alamat: "",
  nomorHp: "",
  request_pengiriman: "",
  catatan: "",
  provinsi: "",
  kota: "",
  kecamatan: "",
  kelurahan: "",
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerData(state, action) {
      return { ...state, ...action.payload };
    },
    clearCustomerData() {
      return initialState;
    },
  },
});

export const { setCustomerData, clearCustomerData } = customerSlice.actions;

export default customerSlice.reducer;
