// features/Menu/MenuPage.js

import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
  faAddressBook,
  faBoxesStacked,
  faCashRegister,
  faChartSimple,
  faFolderTree,
  faListCheck,
  faPercent,
  faTruckFront,
  faUserGear,
  faUsersLine,
} from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons/faAddressCard";
import LogoutButton from "../../components/common/LogoutButton";
import MenuItem from "../../components/common/MenuItem";
import { useMediaQuery, useTheme } from "@mui/material";

const MenuPage = () => {
  const userData = useSelector((state) => state.user.userData);
  console.log("User Data:", userData); // Logging user data untuk verifikasi
  const userName = userData ? userData.username : "Nama Lengkap Anda";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: "url(/assets/images/background-images.webp)",
        backgroundColor: "black",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        margin: 0,
        alignContent: "center",
        overflow: "auto",
      }}
    >
      <LogoutButton />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            color: "white",
            fontWeight: "500",
            m: isMobile ? 2 : 0,
            fontSize: isMobile ? "1.2rem" : "2rem",
          }}
        >
          Hi, {userName} Selamat Datang
        </Typography>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            color: "white",
            fontWeight: "500",
            mt: isMobile ? 2 : 0,
            fontSize: isMobile ? "1.2rem" : "2rem",
          }}
        >
          Selamat Bekerja
        </Typography>
      </Box>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 5 }}
      >
        {/* Baris pertama dari menu items */}
        <Grid item container justifyContent="center">
          <MenuItem icon={faChartSimple} label="Dashboard" to="/dashboard" />
          <MenuItem icon={faCashRegister} label="Penjualan" to="/pin" />
          <MenuItem icon={faListCheck} label="Pesanan" to="/orders" />
          <MenuItem icon={faPercent} label="Promo" to="/promotions" />
        </Grid>
        <Grid item container justifyContent="center">
          <MenuItem icon={faFolderTree} label="Daftar Produk" to="/products" />
          <MenuItem icon={faUsersLine} label="Pelanggan" to="/customers" />
        </Grid>
        {/* 
        <Grid item container justifyContent="center">
          <MenuItem icon={faFolderTree} label="Daftar Produk" to="/products" />
          <MenuItem icon={faAddressBook} label="Buku Tamu" to="/guestbook" />
          <MenuItem icon={faAddressCard} label="Pelanggan" to="/customers" />
        </Grid>
        <Grid item container justifyContent="center">
          <MenuItem icon={faBoxesStacked} label="Inventory" to="/inventory" />
          <MenuItem icon={faTruckFront} label="Pengiriman" to="/shipping" />
          <MenuItem icon={faUserGear} label="Status PO" to="/purchase-orders" />
        </Grid>
         */}
      </Grid>
    </Box>
  );
};
export default MenuPage;
