import React from "react";
import {
  Box,
  Typography,
  Modal,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const PromoModal = ({
  open,
  onClose,
  promotions,
  totalBeforeDiscount,
  onSelect,
}) => {
  const eligiblePromotions = promotions.filter(
    (promotion) => totalBeforeDiscount >= promotion.minimum_purchase
  );
  const ineligiblePromotions = promotions.filter(
    (promotion) => totalBeforeDiscount < promotion.minimum_purchase
  );

  const handleSelectPromotion = (promotion) => {
    onSelect(promotion);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: 400,
          height: 500,
          p: 4,
          backgroundColor: "white",
          margin: "auto",
          mt: "10%",
          borderRadius: 2,
          boxShadow: 24,
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Promo Tersedia
        </Typography>
        <List>
          {eligiblePromotions.map((promotion) => (
            <ListItem
              button
              key={promotion.id}
              onClick={() => handleSelectPromotion(promotion)}
            >
              <ListItemText
                primary={promotion.promotion_name}
                secondary={
                  <>
                    <Typography variant="body2">
                      Syarat: Minimal Pembelian Rp{" "}
                      {promotion.minimum_purchase.toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                      Diskon:{" "}
                      {promotion.promotion_type === "percentage"
                        ? `${
                            promotion.discount_value
                          }% (Maksimal Rp ${promotion.maximum_discount.toLocaleString()})`
                        : `Rp ${promotion.discount_value.toLocaleString()}`}
                    </Typography>
                    {promotion.notes && (
                      <Typography variant="body2">
                        Catatan: {promotion.notes}
                      </Typography>
                    )}
                  </>
                }
              />
            </ListItem>
          ))}
          {ineligiblePromotions.length > 0 && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Tidak Bisa Dipakai
              </Typography>
              {ineligiblePromotions.map((promotion) => (
                <ListItem key={promotion.id} disabled>
                  <ListItemText
                    primary={promotion.promotion_name}
                    secondary={
                      <>
                        <Typography variant="body2">
                          Syarat: Minimal Pembelian Rp{" "}
                          {promotion.minimum_purchase.toLocaleString()}
                        </Typography>
                        <Typography variant="body2">
                          Diskon:{" "}
                          {promotion.promotion_type === "percentage"
                            ? `${
                                promotion.discount_value
                              }% (Maksimal Rp ${promotion.maximum_discount.toLocaleString()})`
                            : `Rp ${promotion.discount_value.toLocaleString()}`}
                        </Typography>
                        {promotion.notes && (
                          <Typography variant="body2">
                            Catatan: {promotion.notes}
                          </Typography>
                        )}
                      </>
                    }
                  />
                </ListItem>
              ))}
            </>
          )}
        </List>
      </Box>
    </Modal>
  );
};

export default PromoModal;
