// path: src/features/Auth/LoginPage.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../actions/userActions";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  InputAdornment,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom"; // Pastikan ini sudah diimpor

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, error } = useSelector((state) => state.auth);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = () => {
    dispatch(loginUser({ username, password }));
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/menu"); // atau jalur yang diinginkan
    }
  }, [isAuthenticated, navigate]);

  return (
    <Box
      container
      sx={{
        display: "flex",
        height: "100vh",
        backgroundImage: "url(/assets/images/background-images.webp)", // Gunakan URL relatif
        backgroundColor: "black",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        margin: 0,
        overflow: "auto",
      }}
    >
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={4}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "16px",
            px: isMobile ? "20px" : "90px",
            py: isMobile ? "20px" : "60px",
            textAlign: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12}>
              <img
                src="/assets/icons/logo-ajeg-hijau.png"
                alt="Ajeg Furniture"
                style={{ width: "80px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align="center"
                gutterBottom
                sx={{ fontWeight: "bold" }}
              >
                Selamat Datang{" "}
              </Typography>
            </Grid>
          </Grid>

          <Typography
            variant="subtitle1"
            align="center"
            gutterBottom
            sx={{ fontSize: "20px" }}
          >
            ~ Silahkan Masuk ~
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{ marginBottom: "40px" }}
          >
            di Aplikasi Manajemen Ajeg Furniture, All in One Solution untuk
            kebutuhan Kasir dan Manajemen Toko.
          </Typography>
          {error && (
            <Typography variant="body2" color="error" align="center">
              {error}
            </Typography>
          )}
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              borderRadius: "8px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />

          <Typography
            variant="body2"
            align="left"
            sx={{
              cursor: "pointer",
              color: "#005a4f", // Ganti dengan warna teks yang kontras dengan latar belakang
              fontWeight: "bold",
            }}
          >
            {" "}
            Lupa Kata Sandi?
          </Typography>
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={handleLogin}
            sx={{
              backgroundColor: "#006D5A", // Ganti dengan warna latar belakang yang diinginkan
              color: "#FFFFFF", // Ganti dengan warna teks yang kontras dengan latar belakang
              mt: 2,
              ":hover": {
                backgroundColor: "#005a4f", // Ganti dengan warna latar belakang yang diinginkan saat tombol dihover
              },
            }}
          >
            Login
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      ></Grid>
    </Box>
  );
};

export default LoginPage;
