// path: src/features/Sales/components/StatusSummary.js
import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../../theme";

const StatusSummary = ({ itemStatusCounts }) => {
  const isMobile = window.innerWidth < theme.breakpoints.values.sm;
  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-around", marginBottom: 2 }}
    >
      {Object.keys(itemStatusCounts).map((status) => (
        <Box
          key={status}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
            borderRadius: 1,
            border: "1px solid",
            width: isMobile ? "50%" : "120px",
          }}
        >
          <Typography variant="subtitle2">{status}</Typography>
          <Typography variant="h6">{itemStatusCounts[status]}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default StatusSummary;
