// path: src/features/Sales/slices/transactionSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { verifyToken } from "../../../actions/userActions";

const baseUrl = process.env.REACT_APP_BASE_URL; // Ambil baseUrl dari environment variable

// Thunk untuk menambahkan transaksi
export const addTransaction = createAsyncThunk(
  "transactions/addTransaction",
  async (transactionData, { getState, rejectWithValue }) => {
    try {
      await verifyToken(); // Verifikasi token sebelum melakukan permintaan

      const token = getState().auth.token; // Ambil token dari state Redux
      const response = await axios.post(
        `${baseUrl}/api/transactions`,
        transactionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
      console.log(error.response.data);
    }
  }
);

// Thunk untuk mendapatkan nomor transaksi terakhir
export const getLastTransactionNumber = createAsyncThunk(
  "transactions/getLastTransactionNumber",
  async (_, { getState, rejectWithValue }) => {
    try {
      await verifyToken(); // Verifikasi token sebelum melakukan permintaan

      const token = getState().auth.token; // Ambil token dari state Redux
      const response = await axios.get(`${baseUrl}/api/transactions/number`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.order_number;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const transactionSlice = createSlice({
  name: "transactions",
  initialState: {
    lastTransactionNumber: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTransaction.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addTransaction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getLastTransactionNumber.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLastTransactionNumber.fulfilled, (state, action) => {
        state.loading = false;
        state.lastTransactionNumber = action.payload;
      })
      .addCase(getLastTransactionNumber.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default transactionSlice.reducer;
