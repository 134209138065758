//path: src/features/Sales/OrderConfirmation/OrderDetails.js
import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  Avatar,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";

const OrderDetails = () => {
  const orderItems = useSelector((state) => state.order.items);

  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const renderCustomizations = (item) => {
    const { additionalOptions, warna, finishing } = item.formData;

    if (additionalOptions && additionalOptions.length > 0) {
      return (
        <Box>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ mt: 1, fontWeight: 600 }}
          >
            Custom:
          </Typography>
          <Grid container>
            {additionalOptions
              .filter((option) => option.nilai)
              .map((option, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="textSecondary">
                      {option.jenis}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="textSecondary">
                      : {option.nilai}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="textSecondary">
                      {formatRupiah(option.harga)}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            <Grid item xs={4}>
              {warna && (
                <Typography variant="body2" color="textSecondary">
                  Warna
                </Typography>
              )}
              {finishing && (
                <Typography variant="body2" color="textSecondary">
                  Finishing
                </Typography>
              )}
            </Grid>

            <Grid item xs={4}>
              {warna && (
                <Typography variant="body2" color="textSecondary">
                  : {warna}
                </Typography>
              )}
              {finishing && (
                <Typography variant="body2" color="textSecondary">
                  : {finishing}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      );
    } else {
      return (
        <Grid container>
          <Grid item xs={4}>
            {warna && (
              <Typography variant="body2" color="textSecondary">
                Warna
              </Typography>
            )}
            {finishing && (
              <Typography variant="body2" color="textSecondary">
                Finishing
              </Typography>
            )}
          </Grid>

          <Grid item xs={4}>
            {warna && (
              <Typography variant="body2" color="textSecondary">
                : {warna}
              </Typography>
            )}
            {finishing && (
              <Typography variant="body2" color="textSecondary">
                : {finishing}
              </Typography>
            )}
          </Grid>
        </Grid>
      );
    }
  };

  const renderSpecifications = (item) => {
    return (
      <Grid container spacing={1}>
        <Grid item container spacing={1} xs={6}>
          <Grid item xs={4}>
            {(item.panjang || item.lebar || item.tinggi) && (
              <Typography variant="body2">Dimensi</Typography>
            )}
            {item.kain && <Typography variant="body2">Kain</Typography>}
            {item.kaki && <Typography variant="body2">Kaki</Typography>}
            {item.dudukan && <Typography variant="body2">Dudukan</Typography>}
          </Grid>
          <Grid item xs={8}>
            {(item.panjang || item.lebar || item.tinggi) && (
              <Typography variant="body2">
                : {item.panjang || "-"} x {item.lebar || "-"} x{" "}
                {item.tinggi || "-"} cm
              </Typography>
            )}
            {item.kain && (
              <Typography variant="body2">: {item.kain}</Typography>
            )}
            {item.kaki && (
              <Typography variant="body2">: {item.kaki}</Typography>
            )}
            {item.dudukan && (
              <Typography variant="body2">: {item.dudukan}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={6}>
          <Grid item xs={8}>
            {item.bantal_peluk !== null && item.bantal_peluk !== undefined && (
              <Typography variant="body2">Bantal Peluk</Typography>
            )}
            {item.bantal_sandaran !== null &&
              item.bantal_sandaran !== undefined && (
                <Typography variant="body2">Bantal Sandaran</Typography>
              )}
            {item.kantong_remot !== null &&
              item.kantong_remot !== undefined && (
                <Typography variant="body2">Kantong Remote</Typography>
              )}
            {item.puff !== null && item.puff !== undefined && (
              <Typography variant="body2">Puff</Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            {item.bantal_peluk !== null && item.bantal_peluk !== undefined && (
              <Typography variant="body2">: {item.bantal_peluk}</Typography>
            )}
            {item.bantal_sandaran !== null &&
              item.bantal_sandaran !== undefined && (
                <Typography variant="body2">
                  : {item.bantal_sandaran}
                </Typography>
              )}
            {item.kantong_remot !== null &&
              item.kantong_remot !== undefined && (
                <Typography variant="body2">: {item.kantong_remot}</Typography>
              )}
            {item.puff !== null && item.puff !== undefined && (
              <Typography variant="body2">
                : {item.puff ? "Ya" : "Tidak"}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        backgroundColor: "white",
        borderRadius: 2,
        padding: 2, // Optional: Add padding for better aesthetics
      }}
    >
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Detail Pesanan
      </Typography>
      <Divider />
      <List>
        {orderItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem sx={{ alignItems: "flex-start" }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar
                    variant="square"
                    src={item.foto_produk}
                    alt={item.nama}
                    sx={{ width: 60, height: 60, borderRadius: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  <ListItemText
                    primary={
                      <Typography variant="body2" sx={{ fontWeight: "600" }}>
                        {item.nama}
                      </Typography>
                    }
                    secondary={
                      <>
                        {item.totalAdditional > 0 && (
                          <Typography variant="body2" color="textSecondary">
                            {formatRupiah(item.harga_jual)} x {item.quantity} +{" "}
                            {formatRupiah(item.totalAdditional)}
                          </Typography>
                        )}
                        <Typography variant="body2" color="textSecondary">
                          Total: {formatRupiah(item.totalHarga)}
                        </Typography>
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontWeight: 600 }}
                  >
                    Spesifikasi:
                  </Typography>
                  {renderSpecifications(item)}
                  {renderCustomizations(item)}
                  {item.note && (
                    <Typography variant="body2" color="textSecondary">
                      Catatan: {item.note}
                    </Typography>
                  )}
                </Grid>
                <Typography></Typography>
              </Grid>
            </ListItem>
            {index < orderItems.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default OrderDetails;
