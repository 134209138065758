// src/features/Sales/OrderConfirmation/MarketingForm.js
import React from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import theme from "../../../theme";

const MarketingForm = ({
  open,
  handleClose,
  handleSubmit,
  marketingData,
  handleMarketingChange,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: theme.boxShadow.primary,
          borderRadius: 4,
          p: 4,
        }}
      >
        <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
          ~ Satu langkah lagi ya kaka ~
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Marketing Form untuk Pelanggan
        </Typography>

        <img
          src="/assets/images/ask-marketing.svg"
          alt="Marketing Form"
          style={{ width: "100%" }}
        />
        <FormControl fullWidth size="small" margin="normal">
          <InputLabel>Tahu Ajeg dari mana?</InputLabel>
          <Select
            name="tahuAjeg"
            value={marketingData.tahuAjeg}
            onChange={handleMarketingChange}
            label="Tahu Ajeg dari mana?"
          >
            <MenuItem value="Instagram">Instagram</MenuItem>
            <MenuItem value="Facebook">Facebook</MenuItem>
            <MenuItem value="Teman">Teman</MenuItem>
            <MenuItem value="Tiktok">Tiktok</MenuItem>
            <MenuItem value="Google">Google</MenuItem>
            <MenuItem value="Maps">Maps</MenuItem>
            <MenuItem value="Shopee">Shopee</MenuItem>
            <MenuItem value="Tokopedia">Tokopedia</MenuItem>
            <MenuItem value="Youtube">Youtube</MenuItem>
            <MenuItem value="Website">Website</MenuItem>
            <MenuItem value="Rekomendasi">Rekomendasi</MenuItem>
            <MenuItem value="Influencer / Artis">Influencer / Artis</MenuItem>
            <MenuItem value="Temen ibu / bapak">Temen ibu / bapak</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth size="small" margin="normal">
          <InputLabel>Closing nya dimana?</InputLabel>
          <Select
            name="closingDimana"
            value={marketingData.closingDimana}
            onChange={handleMarketingChange}
            label="Closing nya dimana?"
          >
            <MenuItem value="Offline">Offline</MenuItem>
            <MenuItem value="Whatsapp">Whatsapp</MenuItem>
            <MenuItem value="Shopee">Shopee</MenuItem>
            <MenuItem value="Tokopedia">Tokopedia</MenuItem>
            <MenuItem value="DM Instagram">DM Instagram</MenuItem>
            <MenuItem value="Website">Website</MenuItem>
            <MenuItem value="Lainnya">Lainnya</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth size="small" margin="normal">
          <InputLabel>Status Pelanggan</InputLabel>
          <Select
            name="statusPelanggan"
            value={marketingData.statusPelanggan}
            onChange={handleMarketingChange}
            label="Status Pelanggan"
          >
            <MenuItem value="Pelanggan Baru">Pelanggan Baru</MenuItem>
            <MenuItem value="Pelanggan Lama">Pelanggan Lama</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" onClick={handleSubmit} fullWidth>
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MarketingForm;
