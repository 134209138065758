// SalesAppBar.js
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import { ArrowBack } from "@mui/icons-material";
import theme from "../../theme";

const SalesAppBar = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "white",
        color: theme.palette.primary.main,
        borderBottom: "1px solid #E4E4E4",
      }}
    >
      <Toolbar>
        {/* Icon Button untuk menu (jika perlu) */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleBack}
          sx={{ marginRight: 2, borderRadius: "50%", border: "1px solid" }}
        >
          <HomeIcon />
        </IconButton>

        {/* Logo dan Judul */}
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          Penjualan
        </Typography>

        {/* Contoh Tombol untuk aksi seperti "Buy" atau "Reservation" */}
        <Button variant="outlined" href="/promotions">
          Buat Promo
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default SalesAppBar;
