// features/Sales/SalesPage.js
import React, { useState, useEffect } from "react";
import { Box, Drawer, useMediaQuery, IconButton } from "@mui/material";
import SalesAppBar from "./SalesAppBar";
import CategoryBar from "./CategoryBar";
import ProductsList from "./ProductsList";
import OrderSummary from "./OrderSummary";
import ProductOrderModal from "./OrderModal/ProductOrderModal";
import { openModal } from "./slices/modalSlice";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuOpen } from "@mui/icons-material";
import theme from "../../theme";

import { fetchProducts, fetchProductDetails } from "../products/productSlice";
import { useSelector, useDispatch } from "react-redux";

const drawerWidth = 350;

const SalesPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    // Menyaring produk berdasarkan kategori yang dipilih
    let filtered = selectedCategory
      ? products.filter((product) => product.kategori === selectedCategory)
      : products;

    // Menyaring produk berdasarkan pencarian
    if (searchQuery) {
      filtered = filtered.filter((product) =>
        product.nama.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredProducts(filtered);
  }, [selectedCategory, searchQuery, products]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleProductClick = async (product) => {
    try {
      const resultAction = await dispatch(
        fetchProductDetails(product.id_produk)
      ).unwrap();
      dispatch(openModal(resultAction)); // Membuka modal dengan detail produk
      console.log("Product details: ", resultAction);
    } catch (error) {
      console.error("Fetching product details failed: ", error.message);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundImage: "radial-gradient(circle, #EEDFED, #E7EFFD)",
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <SalesAppBar />
      <Box
        component="main"
        sx={{ flexGrow: 1, mt: 8, width: "100%", overflow: "auto" }}
      >
        {(isMobile || isTablet) && (
          <Box
            sx={{
              position: "fixed",
              top: 66,
              right: 16,
              borderRadius: "50%", // Sets border radius to make the background circular
              backgroundColor: theme.palette.primary.main, // Background color of the circle
              padding: "10px", // Padding to create space around the icon
              display: "flex", // Use flexbox
              flexDirection: "column", // Stack the icon on top of the text
              justifyContent: "center", // Center the icon horizontally
              alignItems: "center", // Center the icon vertically
              width: "50px", // Adjust the background size to match the icon size
              height: "50px", // Adjust the background size to match the icon size
              mb: 2,
              zIndex: 100,
            }}
          >
            <IconButton
              position="absolute"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ color: "white" }}
            >
              <MenuOpen />
            </IconButton>
          </Box>
        )}
        <CategoryBar
          onCategorySelect={handleCategorySelect}
          onSearch={handleSearch}
        />
        <ProductsList
          products={filteredProducts}
          onProductClick={handleProductClick}
          category={selectedCategory}
          searchQuery={searchQuery}
        />
      </Box>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        anchor="right"
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          marginTop: 8,
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <OrderSummary />
      </Drawer>
      <ProductOrderModal />
    </Box>
  );
};

export default SalesPage;
