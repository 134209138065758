// path: src/features/dashboard/components/CustomDateRangePicker.js

import React, { useState } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { DateRange } from "react-date-range";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  format,
  startOfToday,
  startOfYesterday,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  endOfToday,
  endOfYesterday,
  endOfWeek,
  subMonths,
} from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const CustomDateRangePicker = ({ selectedDateRange, setSelectedDateRange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShortcutClick = (start, end) => {
    setSelectedDateRange([
      { startDate: start, endDate: end, key: "selection" },
    ]);
    handleClose();
  };

  return (
    <>
      <TextField
        variant="outlined"
        value={`${format(
          selectedDateRange[0].startDate,
          "dd MMM yyyy"
        )} - ${format(selectedDateRange[0].endDate, "dd MMM yyyy")}`}
        onClick={handleClick}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DateRangeIcon />
            </InputAdornment>
          ),
        }}
        readOnly
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <List>
            <ListItem
              button
              onClick={() => handleShortcutClick(startOfToday(), endOfToday())}
            >
              <ListItemText primary="Hari ini" />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                handleShortcutClick(startOfYesterday(), endOfYesterday())
              }
            >
              <ListItemText primary="Kemarin" />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                handleShortcutClick(
                  startOfWeek(new Date()),
                  endOfWeek(new Date())
                )
              }
            >
              <ListItemText primary="Minggu Ini" />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                handleShortcutClick(
                  startOfMonth(new Date()),
                  endOfMonth(new Date())
                )
              }
            >
              <ListItemText primary="Bulan Ini" />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                handleShortcutClick(
                  startOfMonth(subMonths(new Date(), 1)),
                  endOfMonth(subMonths(new Date(), 1))
                )
              }
            >
              <ListItemText primary="Bulan Lalu" />
            </ListItem>
          </List>
          <Divider orientation="vertical" flexItem />
          <MenuItem disableRipple>
            <DateRange
              ranges={selectedDateRange}
              onChange={(item) => setSelectedDateRange([item.selection])}
            />
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
};

export default CustomDateRangePicker;
